import React from "react";
import styled from "styled-components";

export const PercentContentWr = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 90px;
  @media(max-width: 1600px){
		padding-right: 60px;
	} 
	@media(max-width: 992px){
		flex-direction: column;
		padding-right: 0;
	}
	
`;
export const PercentP = styled.p`
  max-width: 585px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #0B3D4C;
	margin-top: 140px;
  @media(max-width: 1600px) {
    max-width: 342px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(max-width: 992px) {
    max-width: 357px;
    line-height: 22px;
    margin-top: 24px;
  }
 @media(max-width: 392px) {
    max-width: 300px;
    margin-top: 18px;
	 	font-size: 16px;
	 	line-height: 20px;
  }
 @media(max-width: 322px) {
    max-width: 270px;
  }

`;
export const PercentSpan = styled.span`
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #0B3D4C;
  @media(max-width: 1600px) {
    max-width: 342px;
  }
  @media(max-width: 992px) {
    max-width: 357px;
		font-size: 18px;
    line-height: 22px;
  }
`;
export const PercentBtn = styled.div`
  margin-top: 140px;
	margin-left: 5%;
  @media(max-width: 1600px) {
    margin-left: 0;
    margin-top: 40px;
  }
 @media(max-width: 392px) {
    margin-top: 20px;
  }
@media(max-width: 376px) {
    margin-top: 10px;
  }
`;

export const PercentImgWr = styled.div`
	margin-top: 85px;
	margin-left: -20px;
	& img{
    height: 282px;
    width: 282px;
  }
	@media(max-width: 1600px){
    margin-left: -10px;
    & img{
      height: 260px;
      width: 260px;
    }
	}
  @media(max-width: 992px){
    & img{
      height: 282px;
      width: 282px;
    }
    margin-top: 42px;
	}
 @media(max-width: 322px){
    & img{
      height: 204px;
      width: 204px;
    }
    margin-top: 22px;
	}
`;