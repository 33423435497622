import React from 'react';
import {useTranslation} from "react-i18next";
import {updateTradeItem} from "../../middleware/store/store";
import {scroll} from "../../middleware/scroll";
import {TryFreeBtn} from "./TryFreeBtnStyled";

const GetFreeBtn = () => {

	const { t } = useTranslation();
	const handleTryFreeClick = () => {
		updateTradeItem(0, '1101');
		scroll.scrollTo(scroll.anchors.sendForm);
	}

	return (
		<TryFreeBtn>
			<div className="try-btn" onClick={handleTryFreeClick}>{t('get-free')}</div>
		</TryFreeBtn>
	);

};

export default GetFreeBtn;