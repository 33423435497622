import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import eng from "../img/colorEng.png";
import ua from "../img/color.png";

import {LangWrapper} from "./HeaderStyled";
import {useNavigate} from "react-router-dom";

const SelectLanguage = ({arr}) => {

	const lanObg = [
		{src: eng, name: 'en'},
		{src: ua, name: 'uk'},
	];

	const [lang, setLang] = useState(ua);
	const [showLang, setShowLang] = useState(false);

	const { i18n, t } = useTranslation();
	const currentLang = t('lang');
	const currentUrl = window.location.pathname;
	const navigate = useNavigate();

	const changeLanguage = (language) => {
		if (language.name === 'en'){
					navigate("/en");
				} else {
					navigate("/ua");
				}
		setLang(language.src);
		setShowLang(false);
		window.location.reload(false);

	}
	useEffect(()=>{
		lanObg.filter(item=> item.name === currentLang).map(item=>{
			setLang(item.src)
		})
	},[currentLang]);

	useEffect(()=>{

		if (currentUrl === '/en' && currentLang !== 'en'){
			lanObg.filter(item=> item.name === 'en').map(item=>{
				i18n.changeLanguage(item.name);
			})
		} else if (currentUrl === '/ua' && currentLang !== 'uk'){
			lanObg.filter(item=> item.name === 'uk').map(item=>{
				i18n.changeLanguage(item.name);
				window.location.reload(false);
			})
		}

	},[currentUrl,currentLang]);

	return (
		<LangWrapper>
			<div style={{position: "relative", zIndex: 999}}>
				<div onClick={() => setShowLang(!showLang)}>
					<img src={`${lang}`}/>
					<img src={arr} className='arr'/>
				</div>
				{ showLang && (
					<ul>
						{
							lanObg.map((item, i) => (
								<li key={i} onClick={() => changeLanguage(item)}>
									<img src={item.src}/>
								</li>
							))
						}
					</ul>
				)}
			</div>
		</LangWrapper>
	);
};

export default SelectLanguage;