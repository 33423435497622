import React, {useEffect, useState} from 'react';
import {REVIEWS} from "../../utils";
import {
	ReviewsHeaderWr,
	ReviewsSection,
	ReviewsSlide, ReviewsSlideCourse,
	ReviewsSlideImgWr, ReviewsSlideInfoWr, ReviewsSlideKid, ReviewsSlideName, ReviewsSlideReview,
} from "./ReviewsStyled";
import {SubjectsHeaderWrapper} from "../subjects-section/SubjectsStyles";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import {useTranslation} from "react-i18next";

const Reviews = () => {

	const [people, setPeople] = useState(REVIEWS);
	const settings = {
		dots: true,
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "25%",
		slidesToShow: 1,
		speed: 500,
		nextArrow: <NextArrow/>,
		prevArrow: <PrevArrow/>,
		responsive: [
			{
				breakpoint: 1840,
				settings: {
					centerPadding: "24%"
				}
			},
			{
				breakpoint: 1750,
				settings: {
					centerPadding: "22%"
				}
			},
{
				breakpoint: 1650,
				settings: {
					centerPadding: "21%"
				}
			},
{
				breakpoint: 1550,
				settings: {
					centerPadding: "19%"
				}
			},
{
				breakpoint: 1450,
				settings: {
					centerPadding: "16%"
				}
			},
			{
				breakpoint: 1350,
				settings: {
					centerPadding: "14%"
				}
			},
			{
				breakpoint: 1282,
				settings: {
					centerPadding: "16%"
				}
			},
			{
				breakpoint: 1025,
				settings: {
					centerPadding: "8%"
				}
			},
			{
				breakpoint: 993,
				settings: {
					centerPadding: "4%"
				}
			},
			{
				breakpoint: 415,
				settings: {
					centerPadding: "2%"
				}
			}
		]
	};
	const { t } = useTranslation();

	return (
		<ReviewsSection>
			<ReviewsHeaderWr>
				<SubjectsHeaderWrapper>{t('reviews-header')} </SubjectsHeaderWrapper>
			</ReviewsHeaderWr>
				<Slider {...settings}>
				{people.map((person, personIndex) => {
					const { url, name, kidInfo, course, description} = person;
					return (
							<div key={personIndex}>
								<ReviewsSlide>
									<ReviewsSlideImgWr url={url}>
									</ReviewsSlideImgWr>
									<ReviewsSlideInfoWr>
										<ReviewsSlideName>{t(name)}</ReviewsSlideName>
										<ReviewsSlideKid>{t(kidInfo)}</ReviewsSlideKid>
										<ReviewsSlideCourse>{t(course)}</ReviewsSlideCourse>
										<ReviewsSlideReview>{t(description)}</ReviewsSlideReview>
									</ReviewsSlideInfoWr>
								</ReviewsSlide>
							</div>
					)})}
				</Slider>
		</ReviewsSection>
	);
};

export default Reviews;