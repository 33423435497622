import React from 'react';
import Header from "./header/Header";
import MainSection from "./main-section/MainSection";
import VideoSection from "./video-section/VideoSection";

import {MainContainerWrapper} from "./main-styled/MainStyled";
import Footer from "./footer/Footer";
import Subjects from "./subjects-section/Subjects";
import Peculiarities from "./peculiarities/Peculiarities";
import HowItsWorks from "./how-its-works/HowItsWorks";
import Price from "./price/Price";
import Reviews from "./reviews/Reviews";
import SendForm from "./send-form/SendForm";
import Percent from "./percent/Procent";
import MaPanders from "./ma-panders/MaPanders";

const MainContainer = () => {

	return (

		<MainContainerWrapper>
			<div className="header-main-container">
				<Header/>
				<MainSection/>
			</div>
			<div className="video-container">
				<VideoSection/>
			</div>
			<Subjects/>
			<Peculiarities/>
			<HowItsWorks/>
			<div className="gradient-bg-first">
				<Price/>
				<Reviews/>
				<Percent/>
			</div>
			<div className="gradient-bg-last">
				<MaPanders/>
				<SendForm/>
				<Footer/>
			</div>
		</MainContainerWrapper>
	);
};

export default MainContainer;