import React from "react";
import styled from "styled-components";
import {FlexWrapper, UlWrapper} from "../main-styled/MainStyled";

export const HeaderWrapper = styled.header`
  position: relative;
  font-family: 'MPLUSRounded1c-Regular';
`;
export const BeforeHeader = styled(FlexWrapper)`
  background-color: #EF5A55;
  color: #ffffff;
  height: 62px;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1282px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 540px) {
    & p {
      width: 150px;
      font-size: 16px;
    }

    padding: 0 40px;
  }
  
  @media (max-width: 392px) {
    padding: 0 24px;
    line-height: 20px;
    justify-content: space-between;
  }
  @media (max-width: 322px) {
    & p {
      font-size: 12px;
      width: 120px;
    }
    line-height: 17px;
  }
`;


export const TryButton = styled.button`
  height: 34px;
  width: 161px;
  border-radius: 50px;
  background-color: #ffffff;
  border: none;
  color: #EF5A55;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  margin-left: 16px;
  @media (max-width: 1282px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 322px) {
    margin-left: 0px;
  }
`;

export const LogoNavWrapper = styled.div`
  padding: 40px 12.4% 0 12.4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1400px) {
    padding: 35px 7% 0 7%;
  }

  @media (max-width: 811px) {
    justify-content: center;
  }
  @media (max-width: 392px) {
    padding: 17px 24px 0 24px;
  }
`;
export const LogoWrapper = styled.div`
  height: 70px;
  width: 375px;
	cursor: pointer;
  & img {
    width: 100%;
  }

  @media (max-width: 1282px) {
    height: 60px;
    width: 322px;
  }
  @media (max-width: 870px) {
    display: none;
  }
`;
export const LogoWrapperSmall = styled.div`
  height: 70px;
  width: 73px;
  display: none;
	cursor: pointer;
  & img {
    width: 100%;
  }

  @media (max-width: 870px) {
    display: block;
  }
`;
export const NavWrapper = styled(UlWrapper)`
  max-height: 100px;
  display: flex;
  justify-content: space-between;
  width: 56.6%;
  font-family: 'MPLUSRounded1c-Black';
  font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;

  & li:first-child {
    color: #0B3D4C;
  }

  & li:nth-child(2) {
    color: #0B3D4C;
  }

  & li:nth-child(3) {
    color: #0B3D4C;
  }

  & li:nth-child(4) {
    color: #ffffff;
  }
  & li:hover{
    color: #FFB233;
		
	}
  @media (max-width: 1282px) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: 993px) {
    & li:nth-child(4) {
      color: #0B3D4C;
    }
  }
 @media (max-width: 811px) {
    display: none;
  }

  & li {
    cursor: pointer;
  }
`;

export const LangWrapper = styled.li`
  padding-top: 7px;

  & img {
    height: 20px;
    width: 32px;
  }

  & div div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 61px;

    & .arr {
      width: 16px;
      height: 10px;
    }
  }

  & ul {
    margin: 0;
    list-style-type: none;
    box-sizing: border-box;
    overflow: hidden;
    height: 95px;
    width: 61px;
    padding: 0;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    & li {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
    }

    & li:hover {
      background-color: #F0F1F5;
    }
  }
`;

export const NavBurgerWrapper = styled.div`
  width: 100%;
  display: none;
  @media (max-width: 811px) {
    display: block;
  }
`;

export const Menu = styled.div`
  height: 18px;
  width: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 110px;
  left: 40px;

  & div {
    height: 5px;
    width: 26px;
    border-radius: 50px;
    background-color: #0B3D4C;
  }

  @media (max-width: 392px) {
    top: 102px;
    left: 24px;
  }
`;

export const MenuContent = styled.div`
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  height: 100vh;
  z-index: 50000;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 7% 0;
  }
  @media (max-width: 540px) {
    padding: 13.5% 0;
  }
`;

export const NavBodyBurger = styled.ul`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 15% 0;
  padding: 0 0 30px 0;
  margin: 0;
  font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
  color: #0B3D4C;

  & li {
    cursor: pointer;
  }
`;


export const MarketWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 15%;

  & img {
    width: 54px;
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  & img {
    width: 54px;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
  z-index: 20001;
`;

export const SelectLanguageWrapper = styled.ul`
  display: none;
  position: absolute;
  top: 100px;
  right: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media (max-width: 811px) {
    display: block;
  }
  @media (max-width: 392px) {
    top: 95px;
    right: 24px;
  }
`;