import React from "react";
import styled from "styled-components";
import btn from "../img/Subtract-u.png";

export const ReviewsSection = styled.section`
  width: 100%;
  margin: 6rem auto;
	margin-top: 200px;
  font-family: 'Mulish-Regular';

	@media(max-width: 1367px){
    margin-top: 120px;
	}
  @media(max-width: 811px){
    margin-top: 80px;
  }
  //@media(max-width: 392px){
  //  margin-top: 80px;
  //}
	@media(max-width: 322px){
    margin-top: 60px;
	}
`;

export const ReviewsHeaderWr = styled.div`
  max-width: 640px;
  margin: auto;
	margin-bottom: 80px;
  @media(max-width: 993px){
    margin-bottom: 0px;
  }
`;
export const ReviewsSlides = styled.div`
  margin: 0 auto;
  margin-top: 4rem;
  width: 100%;
  height: 450px;
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const ReviewsSlide = styled.article`
  height: 409px;
  width: 919px;
  background: #FFFFFF;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
	margin-bottom: 50px;
	@media(max-width: 1282px){
    height: 371px;
    width: 834px;
    border-radius: 36px;
    padding: 22px;
  }
	@media(max-width: 992px){
    height: 636px;
    width: 98.5%;
    border-radius: 40px;
    padding: 24px;
		flex-direction: column;
		//justify-content: flex-start;
		justify-content: center;
		gap:24px;
		align-items: center;
  }
  @media(max-width: 435px){
    justify-content: flex-start;
	}
	@media(max-width: 322px){
    padding: 0px;
  }
`;

export const ReviewsSlideImgWr = styled.div`
  background: ${props => `url(${props.url})`};
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
  width: 244px;
  height: 361px;
  border-radius: 40px;
  overflow: hidden;

  @media(max-width: 1282px) {
    height: 327px;
    width: 221px;
    border-radius: 36px;
  }
	@media(max-width: 1282px){
    height: 265px;
    width: 340px;
    border-radius: 40px;
  }
@media(max-width: 392px){
    height: 281px;
    width: 334px;
  border-radius: 24px;
  }
@media(max-width: 322px){
    height: 241px;
    width: 259px;
		margin-top: 25px;
  }
`;

export const ReviewsSlideInfoWr = styled.div`
  padding: 0px 24px;
	@media(max-width: 811px){
    padding: 0px 0px;
	}
	@media(max-width: 435px){
    padding: 0px 0px;
	}
	@media(max-width: 322px){
    padding: 0px 15px;
	}
`;
export const ReviewsSlideName = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #0B3D4C;
	@media(max-width: 1282px){
    font-size: 24px;
    line-height: 31px;
  }
	@media(max-width: 992px){
    font-size: 24px;
    line-height: 31px;
  }
`;
export const ReviewsSlideKid = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #0B3D4C;
  margin: 14px 0 14px 0;
	@media(max-width: 1282px){
    font-size: 18px;
    line-height: 23px;
  }
	@media(max-width: 992px){
    font-size: 18px;
  }
	@media(max-width: 392px){
    font-size: 16px;
		line-height: 20px;
    margin: 8px 0 12px 0;
  }
`;
export const ReviewsSlideCourse = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 301px;
  height: 51px;
  background-color: #F59300;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.07);
  border-radius: 200px;
  box-sizing: border-box;
  margin-bottom: 14px;
	@media(max-width: 1282px){
    height: 41px;
    width: 242px;
    border-radius: 181px;
    padding: 9px 18px;
    font-size: 18px;
    line-height: 23px;
  }
	@media(max-width: 992px){
    height: 43px;
    width: 240px;
    border-radius: 200px;
    padding: 10px 20px;
  }	
	@media(max-width: 392px){
		font-size: 12px;
    height: 24px;
    width: 151px;
    padding: 4px 0px;
  }
`;
export const ReviewsSlideReview = styled.p`
  max-width: 603px;
  max-height: 180px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #0B3D4C;
	@media(max-width: 1282px){
    font-size: 16px;
    line-height: 20px;
  }

`;

export const ReviewsPrev = styled.div`
  position: absolute;
  top: 30%;
  width: 220px;
  height: 220px;
  font-size: 1rem;
  left: 17.8%;
	z-index: 20000;
  @media(max-width: 1600px){
		left: 14%;
	}
  @media(max-width: 1282px){
    left: 2%;
  }
	@media(max-width: 992px){
		left: -1%;
		top: 20%;
	}
  @media(max-width: 435px){
    width: 100px;
    overflow: hidden;
    padding-right: 30px;
    top: 23%;
		& img{
			margin-left: -35px;
		}
  }
  @media(max-width: 415px){
    top: 30%;
    & img{
      width: 150px;
    }
  }

 @media(max-width: 322px){
    top: 25%;
 
  }
	

`;

export  const ReviewsNext = styled.div`
  position: absolute;
  top: 30%;
  width: 220px;
  height: 220px;
  right: 19.5%;
  z-index: 20000;
  @media(max-width: 1600px){
    right: 14%;
  }
	@media(max-width: 1282px){
    right: 5%;
	}
  @media(max-width: 992px){
    right: 0;
    top: 20%;
  }
	@media(max-width: 435px){
			width: 100px;
			overflow: hidden;
			padding-right: 80px;  
			top: 23%;
	}
	@media(max-width: 415px){
    padding-right: 20px;
    top: 30%; 
    & img{
      width: 150px;
    }
	}
	@media(max-width: 322px){
			top: 25%;
	}
`;