import ingForSlideOne from "../components/img/креативне-мислення.png";
import ingForSlideTwo from "../components/img/емоційний-інтелект.png";
import ingForSlideThree from "../components/img/самооцінка-та-комунікація.png";
import ingForSlideFour from "../components/img/навколишній-світ.png";
import ingForSlideFive from "../components/img/фінансова-грамотність.png";
import ingForSlideSix from "../components/img/безпека.png";
import ingForSlideSeven from "../components/img/конфліктологія.png";
import ingForSlideEight from "../components/img/підготовка-до-школи.png";
import ingForSlideNine from "../components/img/життєва-стратегія.png";
import revImgDAr from "../components/img/Daryl.png";
import revImgN from "../components/img/natalie.png";
import revImgD from "../components/img/diana.png";

export const SLIDERS = [
	{url: ingForSlideOne, name: 'study-ways.one', text: 'study-slide-text.one', color: "#00BAE3"},
	{url: ingForSlideTwo, name: 'study-ways.two', text: 'study-slide-text.two', color: "#DB5116"},
	{url: ingForSlideThree, name: 'study-ways.three', text: 'study-slide-text.three',color: "#F59300"},
	{url: ingForSlideFour, name: 'study-ways.four', text: 'study-slide-text.four', color: "#00C265"},
	{url: ingForSlideFive, name: 'study-ways.five', text: 'study-slide-text.five', color: "#BA00D9"},
	{url: ingForSlideSix, name: 'study-ways.six', text: 'study-slide-text.six', color: "#EDAE0E"},
	{url: ingForSlideSeven, name: 'study-ways.seven', text: 'study-slide-text.seven', color: "#3037E9"},
	{url: ingForSlideEight, name: 'study-ways.eight', text: 'study-slide-text.eight', color: "#F500A2"},
	{url: ingForSlideNine, name: 'study-ways.nine', text: 'study-slide-text.nine', color: "#8410DF"},
];

export const REVIEWS = [
	{url: revImgD, name:'reviews.1.name', kidInfo: 'reviews.1.kid-info', course: 'reviews.1.course', description: 'reviews.1.description'},
	{url: revImgN, name:'reviews.2.name', kidInfo: 'reviews.2.kid-info', course: 'reviews.2.course', description: 'reviews.2.description'},
	{url: revImgDAr, name:'reviews.3.name', kidInfo: 'reviews.3.kid-info', course: 'reviews.3.course', description: 'reviews.3.description'},
	{url: revImgD, name:'reviews.4.name', kidInfo: 'reviews.4.kid-info', course: 'reviews.4.course', description: 'reviews.4.description'},
	{url: revImgN, name:'reviews.5.name', kidInfo: 'reviews.5.kid-info', course: 'reviews.5.course', description: 'reviews.5.description'},
	{url: revImgDAr, name:'reviews.6.name', kidInfo: 'reviews.6.kid-info', course: 'reviews.6.course', description: 'reviews.6.description'}
];