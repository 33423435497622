import { configureStore, createSlice } from '@reduxjs/toolkit';

const tradeItemSlice = createSlice({
    name: 'tradeItem',
    initialState: {
        item: {
            sku: '1101',
            price: 0,
        },
    },
    reducers: {
        updatePrice: (state, action) => {
            state.item.price = action.payload;
        },
        updateSku: (state, action) => {
            state.item.sku = action.payload;
        },
    },
});

const store = configureStore({
    reducer: {
        tradeItem: tradeItemSlice.reducer,
    },
});

const { updatePrice, updateSku } = tradeItemSlice.actions;

const updateTradeItem = (price, sku) => {
    store.dispatch(updatePrice(price));
    store.dispatch(updateSku(sku));

    console.warn(`TI: ${JSON.stringify(getTradeItem())}`)
};

const getTradeItem = () => {
    return store.getState().tradeItem.item;
};

export { updateTradeItem, getTradeItem, tradeItemSlice, store };
