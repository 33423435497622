import React from 'react';
import {HowItsWorksDescriptionContainer, HowItsWorksImg} from "./HowItsWorksStyled";


const HowItsWorksDescription = ({numberIs, HowItsWorksDescriptionHeader, HowItsWorksDescriptionInfo, text, textInfo, HowItsWorksImg}) => {
	return (
		<HowItsWorksDescriptionContainer>
				<HowItsWorksImg src={numberIs}/>
			<HowItsWorksDescriptionHeader>{text}</HowItsWorksDescriptionHeader>
			<HowItsWorksDescriptionInfo>{textInfo}</HowItsWorksDescriptionInfo>
		</HowItsWorksDescriptionContainer>
	);
};

export default HowItsWorksDescription;