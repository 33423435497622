import React from 'react';
import left from "../img/navL.png";
import {ReviewsPrev} from "./ReviewsStyled";

const PrevArrow = (props) => {
	const { onClick } = props;
	return (
		<ReviewsPrev className='prev' onClick={onClick}>
			<img src={left}/>
		</ReviewsPrev>
	);
};

export default PrevArrow;