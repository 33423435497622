import React from "react";
import styled from "styled-components";
import cloud from "../img/cloud.png";
import cloudSmall from "../img/cloud-frame.png";
import smaller from "../img/cloud-smal.png";
export const HowItsWorksWr = styled.div`
	//margin-top: 9.5%;
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: -500px;
	overflow: hidden;
  @media (max-width: 392px) {
    margin-top: 20px;
  }
`;
export const HowItsWorksBg = {
	background: `url(${cloud})`,
	backgroundRepeat: 'no-repeat',
	// backgroundSize: 'cover',
	backgroundSize: '100% auto',
	backgroundPosition: 'top',
}
export const HowItsWorksBgSmall = {
	background: `url(${cloudSmall})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% auto',
	backgroundPosition: 'center',
}
export const HowItsWorksBgSmaller = {
	background: `url(${smaller})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center right',
}
export const HowItsWorksContainer = styled.div`
  height: 799px;
  width: 80%;
	margin-top: 9.5%;
	margin-right: 4%;
	padding-left: 4%;
	position: relative;
  @media (max-width: 1600px) {
    height: 584px;
    width: 70%;
    margin-top: 19.5%;
  }
  @media (max-width: 1513px) {
    width: 73%;
  }
  @media (max-width: 1441px) {
    width: 77%;
  }
 @media (max-width: 1367px) {
    width: 82%;
  }
  @media (max-width: 1282px) {
    height: 600px;
    width: 94%;
    margin-right: 0%;
    padding-left: 0%;
  }

 @media (max-width: 1025px) {
   margin-top: 25%;
  }

  @media (max-width: 993px) {
    height: 1710px;
    width: 80%;
		margin-top: 0px;
  }
  @media (max-width: 916px) {
    height: 1650px;
    width: 88%;
  }
  @media (max-width: 870px) {
    //height: 1650px;
    width: 95%;
  }
 @media (max-width: 835px) {
   //height: 1710px;
    width: 95%;
  }
 @media (max-width: 811px) {
   height: 1710px;
    width: 104%;
  }
  //@media (max-width: 769px) {
  //  width: 104%;
  //}
	 @media (max-width: 435px) {
			height: 1683px;
			width: 100%;
			margin-top: 0;
		}
	 @media (max-width: 392px) {
			margin-top: -30px;
		}
`;
export const HowItsWorksDescriptionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
  @media (max-width: 1282px) {
		padding: 0 4% 0 6%;
  }
 @media (max-width: 1025px) {
		padding: 0 0 0 0;
  }
  @media (max-width: 993px) {
		flex-direction: column;
		gap: 50px;
		margin-top: 90px;
    padding: 0 4% 0 6%;
  }
	@media (max-width: 435px) {
			margin-top: 120px;
		}
	@media (max-width: 392px) {
			margin-top: 140px;
		}
`;

export const HowItsWorksDescriptionContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  height: 398px;
  width: 345px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding: 155px 24px 0 24px;
	margin-top: -70px;
	position: relative;
  @media (max-width: 1600px) {
    height: 369px;
    width: 296px;
    margin-top: -170px;
  }
  @media (max-width: 992px) {
		margin-top: 0px;
  }

  @media (max-width: 435px) {
    height: 357px;
    width: 309px;
  }
 @media (max-width: 322px) {
    height: 357px;
    width: 260px;
   padding: 155px 10px 0 10px;
  }
`;
export const HowItsWorksImg = styled.img`
  position: absolute;
  top: -80px;
  @media (max-width: 1600px) {
    top: -80px;
  }

  @media (max-width: 435px) {
		top: -55px
  }
`;
export const HowItsWorksImgTwo = styled(HowItsWorksImg)`
  top: -60px;
	right: 70px;
  @media (max-width: 1600px) {
    top: -60px;
  }

  @media (max-width: 435px) {
		top:-35px;
  }
`;
export const HowItsWorksImgThree = styled(HowItsWorksImg)`
  top: -50px;
  @media (max-width: 1600px) {
    top: -50px;
  }

  @media (max-width: 430px) {
  }
`;
export const HowItsWorksDescriptionHeader = styled.p`
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
  letter-spacing: 0.02em;
  text-align: center;
	color: #EF5A55;
	width: 220px;
	padding-bottom: 5px;
  font-family: 'MPLUSRounded1c-Regular';
  @media (max-width: 1600px) {
    font-size: 24px;
    line-height: 31px;
  }
  @media (max-width: 992px) {
  }

  @media (max-width: 322px) {
		//width: 150px;
  }
`;
export const HowItsWorksDescriptionHeaderOne = styled(HowItsWorksDescriptionHeader)`
	color: #027A9D;
  width: 100%;
  padding-bottom: 20px;

`;
export const HowItsWorksDescriptionHeaderThree = styled(HowItsWorksDescriptionHeader)`
	color: #FA9908;
  width: 100%;
  padding-bottom: 20px;
`;

export const HowItsWorksDescriptionInfo = styled.p`
  font-family: 'Mulish-Regular';
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
	color: #EF5A55;
  @media (max-width: 1600px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 992px) {
  }

  @media (max-width: 435px) {
    font-size: 16px;
    line-height: 20px;
  }
`;


export const HowItsWorksDescriptionInfoOne = styled(HowItsWorksDescriptionInfo)`
	color: #027A9D;
`;
export const HowItsWorksDescriptionInfoThree = styled(HowItsWorksDescriptionInfo)`
	color: #FA9908;
`;

export const HowItsWorksText = styled.div`
	margin-bottom: 40px;
  @media (max-width: 376px){
    margin-bottom: 30px;
	}

`;
export const HowItsWorksTextH = styled.p`
  font-family: 'Mulish-Regular';
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #027A9D;


  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 27px;
  }
 @media (max-width: 392px) {
    font-size: 16px;
    line-height: 23px;
  }

`;
export const HowItsWorksTextM = styled(HowItsWorksTextH)`
`;
export const HowItsWorksTextB = styled(HowItsWorksTextH)`
  font-size: 32px;
  line-height: 42px;
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 31px;
		margin-top: 5px;
  }
`;
export const HowItsWorksTextContainer = styled.div`
	padding-left: 64px;
	margin-top: 109px;
	position: relative;
  @media (max-width: 1513px) {
    padding-left: 50px;
  }
 @media (max-width: 1025px) {
    padding-left: 20px;
    margin-top: 20px;
	//	width: 350px;
  }
  @media (max-width: 993px) {
    padding-left: 124px;
    margin-top: 70px;
		width: 350px;
  }
	@media (max-width: 916px) {
    margin-top: 50px;
  }
	
	@media (max-width: 811px) {
    margin-top: 100px;
  }
	@media (max-width: 768px) {
    margin-top: 70px;
  }
	@media (max-width: 435px) {
			margin-top: 65px;
    	padding-left: 25px;
		}
	@media (max-width: 385px) {
			margin-top: 55px;
    	width: 330px;
		}
	@media (max-width: 376px) {
			margin-top: 45px;
    	width: 320px;
		}
	@media (max-width: 322px) {
			margin-top: 35px;
			width: 300px;
		}
`;


export const ImPencil = styled.div`
	position: absolute;
	top: -5px;
	right: 120px;
  @media (max-width: 1600px) {
    top: -80px;
    right: 80px;
  } 
 @media (max-width: 1025px) {
    top: 10px;
	 & img{
		 width: 150px;
	 }
  } 
	@media (max-width: 993px) {
  	display: none;
  }
`;