import React from "react";
import styled from "styled-components";

export const MaPandaWr = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 130%;
  text-transform: uppercase;
  color: #FCFCFF;
	position: relative;
	margin-left: 25.7%;
	margin-top: 120px;
	margin-bottom: 72px;
	@media(max-width: 1600px){
    width: 423px;
    font-size: 77px;
    line-height: 130%;
    margin-left: 20%;
    margin-bottom: 24px;
		margin-top: 90px;
	}
	@media(max-width: 1025px){
		margin-top: 40px;
    margin-left: 50px;
	}
  @media(max-width: 993px){
    margin-left: 41px;
    margin-top: 40px;
    width: 270px;
    height: 71px;
    font-size: 54px;
  } 
	@media(max-width: 415px){
    margin-left: 21px;
  }

`;

export const MaPandersSpecHead = styled.div`
	position: absolute;
	bottom: 14px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
  height: 62px;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0B3D4C;
  @media(max-width: 1600px){
    font-size: 40px;
    align-items: start;
		bottom: 0;
  }
  @media(max-width: 992px){
    height: 42px;
    left: 0;
		bottom: 10px;
    font-size: 32px;
		& img{
			width: 152px;
		}
	}
`;

export const MaPandaInfo = styled.p`
  max-width: 565px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #0B3D4C;
  margin-left: 25.7%;
	margin-bottom: 20px;
  @media(max-width: 1600px){
    width: 399px;
    font-size: 18px;
    line-height: 23px;
    margin-left: 20%;
  }
 @media(max-width: 1025px){
    margin-left: 50px;
  }
  @media(max-width: 992px){
    width: 349px;
    margin-left: 41px;
    font-size: 16px;
    line-height: 20px;
  }
 @media(max-width: 415px){
    width: 280px;
    margin-left: 21px;
  }
`;

export const MaPandersWr = styled.div`
  margin-top: -71px;
	margin-left: 14%;
	padding-right: 22%;
	@media(max-width: 1600px){
    margin-top: -51px;
    margin-left: 28.5%;
	}
	@media(max-width: 1282px){
    margin-left: 12%;
	}
	@media(max-width: 992px){ 
		margin-left: 36px;
		display: flex;
		flex-direction: column;
		justify-content: right;
		align-items: end;
    padding-right: 20px;
	}
	@media(max-width: 435px){ 
		padding-right: 20px;
    justify-content: left;
    align-items: start;
	}
	@media(max-width: 415px){ 
		margin-left: 22px;
		padding-right: 0px;
		box-sizing: border-box;
	}
	@media(max-width: 392px){ 
		margin-left: 15px;
	}
	@media(max-width: 394px){ 
		margin-left: 12px;
	}
	@media(max-width: 385px){ 
		margin-left: 7px;
	}
	@media(max-width: 362px){ 
		margin-left: 5px;
	}
	@media(max-width: 322px){ 
		margin-left: 0px;
		padding-right: 0px;
		box-sizing: border-box;
	}
`;
export const MaPandersTop = styled.ul`
	list-style-type: none;
	display: flex;
	& img {
      height: 319px;
      width: 157px;
    }
	& li{
		position: relative;
    height: 319px;
    width: 157px;
	}
	& li:nth-child(1){
		margin-right: 30px;
	}
	& li:nth-child(2){
		margin-top: 28%;
		margin-right: 66px;
	}
	& li:nth-child(3){
		margin-top: 3.5%;
		& img{
			position: absolute;
			z-index: 5;
		}
	}
	@media(max-width: 1600px){
    & li{
      height: 225px;
      width: 110px;
    }
    & img{
      height: 225px;
      width: 110px;
    }
    & li:nth-child(1){
      margin-right: 21px;
    }
    & li:nth-child(2){
      margin-right: 47px;
    }
	}
  @media(max-width: 992px){
    & li{
      height: 206px;
      width: 101px;
    }
    & img{
      height: 206px;
      width: 101px;
    }
  }
  @media(max-width: 392px){
    & li:nth-child(1){
      margin-right: 19px;
    }
  }
 @media(max-width: 376px){
    & li:nth-child(2){
      margin-right: 25px;
    }
  }
@media(max-width: 322px){
  & li{
    height: 182px;
    width: 90px;
  }
  & img{
    height: 182px;
    width: 90px;
  }
  & li:nth-child(1){
    margin-right: 10px;
		margin-left: 7px;
  }
    & li:nth-child(2){
      margin-right: 25px;
    }
  }
`;
export const MaPandersBottom = styled(MaPandersTop)`
  & li:nth-child(1){
    margin-top: -120px;
		//margin-left: 5px;
  }
  & li:nth-child(2){
    margin-top: 43px;
		margin-left: 5px;
  }
  & li:nth-child(3){
    margin-top: -116px;
    margin-left: -40px;
  }
	@media(max-width: 1600px){
    & li:nth-child(1){
      margin-top: -70px;
    }
    & li:nth-child(2){
      margin-top: 30px;
      margin-left: 4px;
    }
    & li:nth-child(3){
      margin-top: -71px;
      margin-left: -27px;
    }

  }
@media(max-width: 993px){
    & li:nth-child(1){
			margin-right: 50px;
    }

  }
@media(max-width: 376px){
  & li:nth-child(1){
    margin-right: 10px;
  }
    & li:nth-child(3){
      margin-top: -71px;
      margin-left: -10px;
    }

  }
@media(max-width: 322px){
    & li:nth-child(3){
      margin-top: -55px;
    }
  }
`;

export const MaPandersFotoBorder = styled.div`
  position: absolute;
  width: 157px;
  height: 313px;
  right: 9px;
  top: 0;
  border: 3px solid #000000;
  border-radius: 119px;
  @media(max-width: 1600px){
    height: 219px;
    width: 110px;
    right: 6px;
  }
	@media(max-width: 992px){
    height: 200px;
    width: 101px;
	}
	@media(max-width: 376px){
    right: 0;
	}
	@media(max-width: 322px){
      height: 182px;
      width: 90px;
	}
	
`;

export const MaPandersBorn = styled.div`
  position: absolute;
  left: 55px;
  top: -70px;
	& img{
    width: 33px;
    height: 124px;
	}
  @media(max-width: 1600px){
    left: 38px;
    top: -50px;
    & img{
      height: 88px;
      width: 24px;
    }
  }
 @media(max-width: 992px){
    top: -45px;
    & img{
      height: 80px;
      width: 21px;
    }
  }
 @media(max-width: 322px){
    top: -35px;
    & img{
      height: 60px;
      width: 15px;
    }
  }

`;
export const MaPandersHorse = styled.div`
  position: absolute;
  right: -25px;
  top: -67px;
  & img{
    width: 106px;
    height: 238px;
  }
	@media(max-width: 1600px){
    right: -17px;
    top: -47px;
    & img{
      height: 168px;
      width: 75px;
    }
  }
	@media(max-width: 992px){
    top: -43px;
    & img{
      height: 154px;
      width: 68px;
    }
  }
	@media(max-width: 322px){
    top: -40px;
    & img{
      height: 134px;
      width: 60px;
    }
  }
`;

export const MaPandersFotoBorderTwo = styled(MaPandersFotoBorder)`
  left: 4px;
  top: 6px;
	@media(max-width: 376px){
    left: 2px;
    //top: 6px;
	}
	@media(max-width: 322px){
    left: 0;
    top: 1px;
	}
`;

export const MaPandersCatLeft = styled.div`
  position: absolute;
  left: -40px;
  top: -17px;
	& img{
    width: 84px;
    height: 96px;
	}
  @media(max-width: 1600px){  
		left: -30px;
    top: -10px;
      & img{
        height: 68px;
        width: 59px;
      }  
		}
  @media(max-width: 992px){  
		left: -25px;
      & img{
        height: 62px;
        width: 54px;
      }  
		}
 @media(max-width: 322px){  
		left: -18px;
      & img{
        height: 50px;
        width: 43px;
      }  
		}

`;
export const MaPandersCatRight = styled.div`
  position: absolute;
  right: -40px;
  top: -17px;
  & img{
    width: 84px;
    height: 96px;
  }
  @media(max-width: 1600px){
    right: -30px;
    top: -10px;
    & img{
      height: 68px;
      width: 59px;
    }
  }
  @media(max-width: 992px){
    right: -25px;
    & img{
      height: 62px;
      width: 54px;
    }
  }
 @media(max-width: 322px){
    right: -18px;
   & img{
     height: 50px;
     width: 43px;
   }
 }
`;

export const MaPandersCatLeftOne  = styled.div`
  position: absolute;
  left: -40px;
  top: 100px;
  & img{
    height: 64px;
    width: 88px;
  }
  @media(max-width: 1600px){
    left: -30px;
    top: 80px;
    & img{
      height: 45px;
      width: 62px;
    }
  }
  @media(max-width: 992px){
		left: -30px;
    top: 80px;
    & img{
      height: 41px;
      width: 56px;
    }
  }
  @media(max-width: 322px){
		left: -25px;
    top: 50px;
    & img{
      height: 35px;
      width: 46px;
    }
  }
`;
export const MaPandersCatRightTwo  = styled.div`
  position: absolute;
  right: 50px;
  top: 90px;
  & img{
    height: 64px;
    width: 88px;
  }
  @media(max-width: 1600px){
    right: 30px;
    top: 70px;
    & img{    
			height: 45px;
      width: 62px;
    }
  }
  @media(max-width: 1367px){
    right: 0px;
  }
  @media(max-width: 1282px){
    right: 30px;
  }
 @media(max-width: 1025px){
    right: 0px;
  }
  @media(max-width: 993px){
		right: 30px;
    top: 70px;
    & img{
      height: 41px;
      width: 56px;
    }
  }
  @media(max-width: 322px){
    top: 60px;
    & img{
      height: 35px;
      width: 46px;
    }
  }
`;