import React from "react";
import styled from "styled-components";

export const FormSubmitBtn = styled.div`
  height: 72px;
  width: 371px;
  border-radius: 32px;
	overflow: hidden;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.02em;
	color: #ffffff;
	cursor: pointer;
	margin: 0;
	padding: 0;
	& :hover{
		color: lightgray;
		transition: 0.5s;
	}
  @media (max-width: 1600px) {
    width: 308px;
    font-size: 20px;
    line-height: 30px;
  }
 @media (max-width: 322px) {
    width: 260px;
    font-size: 18px;
    line-height: 27px;
  }
`;