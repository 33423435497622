import React from "react";
import styled from "styled-components";
export const MainContainerWrapper = styled.div`
	max-width: 1920px;
	min-width: 320px;
  font-family: 'MPLUSRounded1c-Regular';
	overflow: hidden;
	margin: 0 auto;
`;

export const FlexWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const UlWrapper = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
`;
