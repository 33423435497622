import React from 'react';
import per from "../img/pre.png";
import TryFree from "../try-free/TryFree";
import {PercentBtn, PercentContentWr, PercentImgWr, PercentP, PercentSpan} from "./PercentStyled";
import {useTranslation} from "react-i18next";
const Percent = () => {
	const { t } = useTranslation();
	return (
		<div className="percent-bg">
			<PercentContentWr>
				<PercentImgWr>
					<img src={per}/>
				</PercentImgWr>
				<PercentP>
					{t('percent')}<br/>
					<PercentSpan>{t('percent-do')}</PercentSpan>
				</PercentP>
				<PercentBtn><TryFree/></PercentBtn>
			</PercentContentWr>
		</div>
	);
};

export default Percent;