import React, { useEffect, useRef, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import {logDOM} from "@testing-library/react";
import {useTranslation} from "react-i18next";
function PhoneNumberInput({classNamePh, setUserPhone, userPhone, placeholder,setSelectedCountryCode}) {

	const inputRef = useRef(null);
	const [defaultCountry, setDefaultCountry] = useState('');

	const { t } = useTranslation();
	const currentLang = t('lang');

	useEffect(() => {
		const userLanguage = navigator.language || navigator.userLanguage;

		if (userLanguage.startsWith('ru') && currentLang !== "en") {
			setDefaultCountry('ua');
			setSelectedCountryCode('380');// Установка страны по умолчанию
		} else if (userLanguage.startsWith('en')) {
			setDefaultCountry('us');
			setSelectedCountryCode('1');// Установка страны по умолчанию
		}else if (currentLang === "en") {
			setDefaultCountry('us');
			setSelectedCountryCode('1');// Установка страны по умолчанию
		}

		const input = intlTelInput(inputRef.current, {
			initialCountry: defaultCountry,
			separateDialCode: true,
			// Другие опции и настройки
		});

		// Обработка изменений
		inputRef.current.addEventListener('countrychange', () => {
			const isValid = input.isValidNumber();
			const countryCode = input.getSelectedCountryData().iso2;
			const dialCode = input.getSelectedCountryData().dialCode;
			// Обработка выбранного кода страны, телефонного кода и валидности номера
			console.log('IsValid:', isValid);
			console.log('Country Code:', countryCode);
			console.log('Dial Code:', dialCode);
			setSelectedCountryCode(dialCode);
		});

		return () => {
			// Очистка при размонтировании компонента
			input.destroy();
		};
	}, [defaultCountry, currentLang]);

	return(
	<input  ref={inputRef}
					type="tel"
					placeholder={placeholder}
					className={classNamePh}
					onChange={(ev) => {
						setUserPhone(ev.target.value);
					}}
					value={userPhone}
	/>
)
}


export default PhoneNumberInput;





//
// import React, { useEffect, useRef } from 'react';
// import 'intl-tel-input/build/css/intlTelInput.css';
// import intlTelInput from 'intl-tel-input';
//
// function PhoneNumberInput() {
// 	const inputRef = useRef(null);
//
// 	useEffect(() => {
// 		const input = intlTelInput(inputRef.current, {
// 			initialCountry: 'auto',
// 			separateDialCode: true,
// 			// Другие опции и настройки
// 		});
//
// 		// Обработка изменений
// 		inputRef.current.addEventListener('countrychange', () => {
// 			const isValid = input.isValidNumber();
// 			const countryCode = input.getSelectedCountryData().iso2;
// 			const dialCode = input.getSelectedCountryData().dialCode;
// 			// Обработка выбранного кода страны, телефонного кода и валидности номера
// 			console.log('IsValid:', isValid);
// 			console.log('Country Code:', countryCode);
// 			console.log('Dial Code:', dialCode);
// 		});
//
// 		return () => {
// 			// Очистка при размонтировании компонента
// 			input.destroy();
// 		};
// 	}, []);
//
// 	return (
// 		<input ref={inputRef} type="tel" />
// 	);
// }
//
// export default PhoneNumberInput;
