import React from "react";
import styled from "styled-components";

export const SubjectsHeaderWrapper = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: #0B3D4C;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
	margin-top: 200px;
  font-family: 'MPLUSRounded1c-Regular';
  & img {
    margin-left: -35px;
  }
  @media (max-width: 1602px){
    margin-top: 150px;
    & img {
			width: 130px;
			margin-top: 10px;
    }
  }
 @media (max-width: 1025px){
    margin-top: 70px;
  }
	@media (max-width: 993px){
		margin-top: 70px;
    font-size: 32px;
    line-height: 42px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 20px;
		& img {
			margin-bottom: -35px;
		}
	}
	@media (max-width: 993px){
    margin-top: 20px;
	}	
	@media (max-width: 916px){
    margin-top: 30px;
	}	
	@media (max-width: 769px){
    margin-top: 0px;
	}
 @media (max-width: 392px){
    font-size:28px;
    line-height: 33px;
   	margin-top: 40px;
   	padding: 0 20px 35px 20px;
	 
  }

`;

export const SubjectsNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12%;
  box-sizing: border-box;
  @media (max-width: 1285px){
    padding: 0 7%;
  }
	@media (max-width: 992px){
			padding: 0 0 0 5%;
		}
`;

export const SubjectsNameTopWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
	flex-wrap: wrap;
  list-style-type: none;
	gap: 20px;
	margin-bottom: 20px;
	padding: 0 2%;
	box-sizing: border-box;
	width: 100%;
  font-family: 'MPLUSRounded1c-Regular';
  @media (max-width: 992px) {
    justify-content: normal;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  & li {
		cursor: pointer;
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    letter-spacing: 0.02em;
    border-radius: 200px;
    padding: 10px 20px;
    white-space: nowrap;
    @media (max-width: 1600px) {
			font-size: 16px;
      line-height: 20px;
    }
  }

  & li:nth-child(1) {
    border: 3px solid #00BAE3;
    color: #00BAE3;
  }

  & li:nth-child(2) {
    border: 3px solid #DB5116;
    color: #DB5116;
  }

  & li:nth-child(3) {
    border: 3px solid #F59300;
    color: #F59300;
  }

  & li:nth-child(4) {
    border: 3px solid #00C265;
    color: #00C265;
  }
  & li:nth-child(5) {
    border: 3px solid #BA00D9;
    color: #BA00D9;
  }

  & li:nth-child(6) {
    border: 3px solid #EDAE0E;
    color: #EDAE0E;
  }

  & li:nth-child(7) {
    border: 3px solid #3037E9;
    color: #3037E9;
  }

  & li:nth-child(8) {
    border: 3px solid #F500A2;
    color: #F500A2;
  }
  & li:nth-child(9) {
    border: 3px solid #8410DF;
    color: #8410DF;
  }
`;


export const SubjectsSliderWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	padding-top: 2%;
	transition: 0.5s;
	box-sizing: border-box;
	overflow: hidden;
  @media (max-width: 992px){
		flex-direction: column;
		padding: 0 ;
	}
`;

export const SubjectsSliderPrev = styled.div`
	position: absolute;
	top: 35%;
	left: -40px;
  @media (max-width: 1367px){
    left: -60px;
	}
  @media (max-width: 1025px) {
    top: 200px;
    //left: -60px;
    & img {
      width: 200px;
    }
  }
    @media (max-width: 435px){
      left: -20px;
      & img{
        width: 150px;
      }
    }
 		@media (max-width: 392px){
      left: -50px;
			top: 250px;
    }
		@media (max-width: 392px){
			top: 200px;
    }
	
`;
export const SubjectsSliderNext = styled.div`
  position: absolute;
  top: 35%;
	right: -40px;
  @media (max-width: 1367px){
    right: -60px;
	}
  @media (max-width: 1025px) {
    top: 200px;
    right: -70px;
    & img {
      width: 200px;
    }
  }
  @media (max-width: 993px) {
    right: 0;
  }
    @media (max-width: 435px){
      & img{
        width: 150px;
      }
  }
 		@media (max-width: 392px){
			right: -25px;
			top: 200px;
  }
`;

export const SubjectsSliderImg = styled.div`
	width: 40%;
	padding-left: 1%;
  box-sizing: border-box;
	& img {
      width: 100%;
      @media (max-width: 1025px) {
        width: 400px;
        margin-right: 50px;
      }
 		//	@media (max-width: 993px) {
      //  width: 400px;
      //  margin-right: 50px;
      //}
      @media (max-width: 392px) {
        min-width: 300px;
        margin-right: 20px;
      }
    }
  @media (max-width: 992px){
    width: 100%;
    padding-left: 0;
		display: flex;
		justify-content: center;
  }

`;

export const SubjectsSliderInfo = styled.div`
	width: 60%;
	padding: 0 10% 0 50px;
  box-sizing: border-box;
	& p:last-child{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
		color: #0B3D4C;
		font-family: 'Mulish-Regular';
    @media (max-width: 1600px){
      font-size: 18px;
      line-height: 23px;
    }
    @media (max-width: 1025px){
      font-size: 16px;
      line-height: 20px;
    }
   @media (max-width: 993px){
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 992px){
    width: 100%;
    padding: 0 20px 0 0;
	}
 @media (max-width: 392px){
    padding: 10px 20px 0 0;
	}
 @media (max-width: 322px){
    padding: 25px 20px 0 0;
	}
		
`;

export const TitleSubjectSlider = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.02em;
	color: ${props => props.color};
	padding-bottom: 30px;
  box-sizing: border-box;
  font-family: 'MPLUSRounded1c-Regular';
  @media (max-width: 1600px){
    font-size: 40px;
    line-height: 52px;
  }
	@media (max-width: 1367px){
			font-size: 33px;
		}
	@media (max-width: 993px){
			text-align: center;
		}
	@media (max-width: 392px){
			text-align: left;
    	padding-bottom: 10px;
			padding-top: 10px;
		}
	@media (max-width: 322px){
			font-size: 28px;
			line-height: 36px;
			padding-top: 10px;
		}

`;