import React from 'react';
import {useTranslation} from "react-i18next";
import {SLIDERS} from "../../utils";

import prev from "../img/nav.png";
import next from "../img/nav-right.png";

import {
	SubjectsSliderImg,
	SubjectsSliderInfo,
	SubjectsSliderNext,
	SubjectsSliderPrev,
	SubjectsSliderWrapper,
	TitleSubjectSlider
} from "./SubjectsStyles";



const SubjectsSlider = ({ handlerNext, handlerPrevious, currentIndex}) => {

	const {t} = useTranslation();

	return (
		<SubjectsSliderWrapper>
			<SubjectsSliderPrev onClick={handlerPrevious}><img src={prev}/></SubjectsSliderPrev>
			<SubjectsSliderNext onClick={handlerNext}><img src={next}/></SubjectsSliderNext>
			<SubjectsSliderImg><img src={SLIDERS[currentIndex].url}/></SubjectsSliderImg>
			<SubjectsSliderInfo>
				<TitleSubjectSlider color={SLIDERS[currentIndex].color}>{t(SLIDERS[currentIndex].name)}</TitleSubjectSlider>
				<p>{t(SLIDERS[currentIndex].text)}</p>
			</SubjectsSliderInfo>
		</SubjectsSliderWrapper>
	);
};

export default SubjectsSlider;