import React from "react";
import styled from "styled-components";

export const VideoCharacterInfoWr = styled.div`
	display: flex;
	gap: 43px;
	width: 100%;
	align-items: center;
	padding-left: 18.2%;
	padding-top: 11.5%;
	box-sizing: border-box;
  font-family: 'Mulish-Regular';
  @media (max-width: 1400px){
    padding-left: 8%;
    padding-top: 11.5%;
	}
  @media (max-width: 1400px){
    padding-left: 8%;
    padding-top: 11.5%;
	}

`;
export const VideoSectionImgWr = styled.div`
  & img{
    height: 537px;
    width: 402px;
	}
  @media (max-width: 1600px){
    & img{
      height: 438px;
      width: 328px;
    }
	}
  @media (max-width: 1025px){
    & img{
      height: 347px;
      width: 260px;
    }
	}
  @media (max-width: 992px){
		position: absolute;
		z-index: 5;
		left: 41%;
		top: 0;
    //& img{
    //  height: 347px;
    //  width: 260px;
    //}
	}
  @media (max-width: 916px){
    left: 36%;
	}
 @media (max-width: 870px){
    left: 35%;
	}
 	//@media (max-width: 768px){
    //left: 35%;
	//}
	 @media (max-width: 540px){
			left: 70px;
		}
	 @media (max-width: 392px){  
		 left: 85px;
		 top: 10px;
     & img{
       height: 273px;
       width: 204px;
     }
		}
 @media (max-width: 322px){  
		 left: 70px;
		 top: 30px;
     & img{
       height: 252px;
       width:188px;
     }
		}
`;

export const VideoSectionInfo = styled.div`
  height: 800px;//+50px
  width: 887px;
	box-sizing: border-box;
  padding: 160px 93px 0 195px;
	overflow: hidden;
  @media (max-width: 1600px){
    width: 700px;
    padding: 200px 93px 0 150px;
  }
	 @media (max-width: 993px){
     height: 820px;
     width: 460px;
     padding: 260px 42px 0 48px;
		}
	 @media (max-width: 435px){
     height: 820px;
     width: 430px;
     padding: 260px 42px 0 30px;
		}
	 @media (max-width: 392px){
     height: 780px;
     width: 390px;
     padding: 220px 10px 0 20px;
		}
	 @media (max-width: 322px){
     height: 825px;
     width: 320px;
		}
`;

export const VideoSectionInfoHeader = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0B3D4C;
	margin-bottom: 10px;
	margin-top: 10px;
  font-family: 'MPLUSRounded1c-Regular';

  @media (max-width: 1600px){
    line-height: 52px;
  }
  @media (max-width: 992px){
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  @media (max-width: 392px){
    margin-bottom: 12px;
		width: 332px;
  }
  @media (max-width: 322px){
		width: 260px;
		font-size: 24px;
  }

`;
export const VideoSectionInfoInfo = styled.p`
  font-family: 'Mulish-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #0B3D4C;
  @media (max-width: 1600px){
    font-size: 18px;
    line-height: 23px;
  }
	 @media (max-width: 992px){
			font-size: 16px;
	 }

	@media (max-width: 400px){  
		width: 332px;
	}
  
 	@media (max-width: 322px){
			width: 260px; 
	 }
`;
export const VideoSectionInfoInfoTwo = styled(VideoSectionInfoInfo)`
	margin-top: 20px;
	margin-bottom: 20px;
	@media(max-width: 322px){
		margin-bottom: 8px;
	}
`;
export const VideoWrapper = styled.div`
  position: relative;
  width: 1170px;
  height: 638px;
  border: 10px solid #fff;
  filter: drop-shadow(0px 15px 60px rgba(0, 0, 0, 0.07));
  border-radius: 50px;
	overflow: hidden;
 	margin: 0 auto;
	margin-top: -460px;
	margin-bottom: 125px;
	display: flex;
	justify-content: center;
	align-items: center;
  @media (max-width: 1601px){
    height: 542px;
    width: 1000px;
    margin-top: -340px;
    margin-bottom: 80px;
  }
	@media (max-width: 1400px){
    margin-top: -340px;
    margin-bottom: 80px;
		}
	@media (max-width: 1025px){
    margin-top: -440px;
		width: 90%;
    height: 500px;
		}
	@media (max-width: 992px){
    margin-top: 50px;
    margin-bottom: 80px;
		background-color: #191919;
    //height: 243px;
    //width: 353px;
    //height: 100vh;
    //width: 100%;
    //width: 40%;
    height: 504px;
    width: 353px;
  }
	@media (max-width: 435px){
    //height: 504px;
    //width: 353px;
  }
	@media (max-width: 392px){
    height: 473px;
		margin-top: -70px;
		background-color: #ffffff;
    margin-bottom: 0;
  }
	@media (max-width: 322px){
    height: 380px;
    width: 279px;
		margin-top: -35px;
  }
`;

export const PlayBtn = styled.div`
	position: absolute;
	top: 32%;
	left: 40%;
	& img{
    height: 253px;
    width: 246px;
  }
  @media (max-width: 1400px){
    & img{
      height: 217px;
      width: 211px;
    }
	}
 @media (max-width: 992px){
   //top: 47px;
   //left: 104px;
   top: 177px;
   left: 104px;
    & img{
      height: 149px;
      width: 145px;
    }
	}
//@media (max-width: 435px){
//   top: 177px;
//   left: 104px;
//	}
@media (max-width: 322px){
   top: 140px;
   left: 90px;
    & img{
      height: 110px;
      width: 108px;
    }
	}
`;
export const VideoStyle = styled.video`
	width: 100%;
  @media (max-width: 992px){
		width: 130%;
	}
	
  &[poster]{
   
  }
`;