import React from "react";
import styled from "styled-components";
import btn from "../img/Subtract-u.png";

export const PriceAll = styled.div`
  padding-top: 25px;
  font-family: 'MPLUSRounded1c-Regular';
  @media (max-width: 392px) {
    padding-top: 0;
  }
`;

export const PriceBoxesWr = styled.div`
  display: flex;
  padding: 80px 10%;
  justify-content: space-between;
  align-items: end;

  @media (max-width: 1600px) {
    padding: 40px 7%;
  }
  @media (max-width: 1110px) {
    padding: 40px 1%;
    justify-content: space-evenly;
  
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
  @media (max-width: 392px) {
    padding: 40px 7% 20px 7%;
  }

`;

export const PriceBox = styled.div`
  width: 329px;
  height: 378px;
  background: #FFFFFF;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 14px 11px;
  position: relative;
  //@media (max-width: 1110px) {
  // margin: 0 20px
  //}

  @media (max-width: 1600px) {
    height: 269px;
    width: 234px;
    border-radius: 28px;
    padding: 11px 8px;
  }
  @media (max-width: 992px) {
    height: 378px;
    width: 329px;
    border-radius: 40px;
    padding: 14px 11px;
  }
  @media (max-width: 322px) {
    height: 358px;
    width: 290px;
  }
`;

export const PriceBoxGrayBg = styled.div`
  width: 307px;
  height: 296px;
  background: #F0F1F5;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 28px 20px;
  position: relative;
  @media (max-width: 1600px) {
    height: 210px;
    width: 218px;
    border-radius: 28px;
    padding: 17px 14px;
  }
  @media (max-width: 992px) {
    width: 307px;
    height: 296px;
    border-radius: 40px;
    padding: 28px 20px;
  }
  @media (max-width: 322px) {
    width: 270px;
    height: 281px;
    padding: 20px 0;
  }
`;
export const PriceBoxHeader = styled.div`
  font-family: 'MPLUSRounded1c-Regular';
  font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 8px;
  color: #0B3D4C;
  @media (max-width: 1600px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 6px;
    margin-top: 0;
  }
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 15px;
    margin-top: 8px;
  }

`;

export const PricePr = styled.div`
  font-family: 'MPLUSRounded1c-Regular';
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #0B3D4C;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 1600px) {
    font-size: 34px;
    line-height: 44px;
  }
  @media (max-width: 992px) {
    font-size: 48px;
    line-height: 62px;
  }
`;
export const PricePrGr = styled.div`
  font-family: 'Mulish-Regular';
  font-style: normal;
  font-weight: 800;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #A29EB6;
  position: relative;
  @media (max-width: 1600px) {
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
  }
  @media (max-width: 992px) {
    font-size: 16px;
    font-weight: 800;
    line-height: 21px;
  }
`;

export const PricePrBlack = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #0B3D4C;
  text-transform: uppercase;
  position: relative;
  @media (max-width: 1600px) {
    font-size: 17px;
    line-height: 22px;
  }
`;
export const PricePrWhite = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #F0F1F5;
  text-transform: uppercase;
  position: relative;
  @media (max-width: 1600px) {
    font-size: 17px;
    line-height: 22px;
  }
`;

export const LinePriseWr = styled.div`
  position: absolute;
  top: 0;
  right: 70px;
  @media (max-width: 1600px) {
    right: 32px;
    & img {
      width: 70%;
    }
  }
  @media (max-width: 992px) {
    right: 70px;
    & img {
      width: 70%;
    }
  }
`;
export const PriceBoxGreenWr = styled.div`
  display: flex;
  gap: 7px;
  margin-top: 23px;

  & img {
    height: 17px;
    width: 21px;
    @media (max-width: 1600px) {
      height: 13px;
      width: 15px;
    }
    @media (max-width: 992px) {
      height: 17px;
      width: 21px;
    }
    @media (max-width: 322px) {
      margin-top: 5px;
    }
  }

  @media (max-width: 1600px) {
    margin-top: 14px;
  }
  @media (max-width: 992px) {
    margin-top: 23px;
  }
  @media (max-width: 322px) {
    margin-top: 17px;
    padding: 0 30px;
  }
`;
export const PriceBoxGreen = styled.div`
  width: 238px;
  font-family: 'Mulish-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #56C423;
  text-align: left;
  @media (max-width: 1600px) {
    font-size: 12px;
    line-height: 15px;
    width: 170px;
  }
  @media (max-width: 992px) {
    width: 238px;
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 322px) {
    font-size: 12px;
    line-height: 15px;
  }


`;

export const GreenWrDiv = styled.div`
  height: 422px;
  width: 339px;
  border-radius: 40px;
  background-color: #6BCD3D;
  box-sizing: border-box;
  padding: 10px 5px 5px 5px;
  @media (max-width: 1600px) {
    height: 297px;
    width: 241px;
    border-radius: 28px;
    padding: 5px 3px 3px 3px;
  }
  @media (max-width: 992px) {
    height: 422px;
    width: 339px;
    border-radius: 40px;
    padding: 10px 5px 5px 5px;
  }
  @media (max-width: 322px) {
    height: 406px;
    width: 300px;
    border-radius: 40px;
    padding: 10px 5px 5px 5px;
  }
`;

export const GreenWrDivOpt = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 8px;
  @media (max-width: 1600px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
  }
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 8px;
  }
`;
export const PriceBtnBg = {
	background: `url(${btn})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	backgroundPosition: 'center',
}
export const PriceBtnWr = styled.button`
  height: 66px;
  width: 267px;
  border-radius: 30px;
  border: none;
  position: absolute;
  bottom: 24px;
  font-size: 19px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 1600px) {
    height: 47px;
    width: 190px;
    border-radius: 21px;
    bottom: 17px;
    right: 14px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

  }
  @media (max-width: 992px) {
    right: 14px;
    height: 66px;
    width: 267px;
    border-radius: 30px;
    bottom: 24px;
    font-size: 19px;
    line-height: 29px;
  }
  @media (max-width: 322px) {
    right: 24px;
    height: 54px;
    width: 218px;
  }
`;

export const PriceTryFree = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SaleWr = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 200;
  @media (max-width: 1600px) {
    top: -5px;
    right: -4px;
    height: 78px;
    width: 78px;
    & img {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    top: -8px;
    right: -5px;
    height: 119.5px;
    width: 116px;
    & img {
      width: 100%;
    }
  }
  @media (max-width: 392px) {
    top: -5px;
`;

export const SalePercent = styled.div`
  position: absolute;
  transform: rotate(45deg);
  top: 38px;
  right: -20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'MPLUSRounded1c-Regular';
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #ffffff;
  @media (max-width: 1602px) {
    top: 20px;
    font-size: 12px;
    right: -10px;
  }
  @media (max-width: 992px) {
    top: 38px;
    right: -20px;
    font-size: 16px;
  }
  @media (max-width: 392px) {
    top: 38px;
  }
`;