import React from 'react';
import {ReviewsNext} from "./ReviewsStyled";
import right from "../img/navR.png";

const NextArrow = (props) => {
	const { onClick } = props;
	return (
	<ReviewsNext className='next' onClick={onClick}>
		<img src={right}/>
	</ReviewsNext>
	);
};

export default NextArrow;