import React from 'react';
import {
	CopyWrapper,
	Email,
	FooterContent,
	MarketFooterWrapper,
	Phone,
	SocialFooter,
	SocialFooterWrapper
} from "./FooterStyled";
import appStore from "../img/App_Store button-p.png";
import playMarket from "../img/google play-p.png";
import {useTranslation} from "react-i18next";
import facebook from "../img/facebook.png";
import instagram from "../img/Instagram_logo_2016 1.png";
import viber from "../img/viber.png";
import telegram from "../img/telegram.png";
import email from "../img/Icons.png";
import phone from "../img/Vector-i.png";
import {scroll} from "../../middleware/scroll";

const Footer = () => {
	const phoneNumber = '+380961690439';
	const emailAddress = 'support@mapanda.com';
	const { t } = useTranslation();


	const simulateCall = phoneNumber => window.open(`tel:${phoneNumber}`, '_self');

	const onPhoneClick = () => {
	  simulateCall(phoneNumber)
	}

	const sendEmail = () => {
		window.open(`mailto:${emailAddress}`);
	}

	return (
		<>
			<div className='footer-bg'>
				<FooterContent>
					<MarketFooterWrapper>
						<p><a href={t('terms-of-use-link')}>{t('use-term')}</a></p>
						<div>
							<a href={"https://apps.apple.com/us/app/mapanda/id1671497001"}><img src={appStore}/></a>
							<a href={"https://play.google.com/store/apps/details?id=com.mapanda.mp.learning"}><img src={playMarket}/></a>
						</div>
					</MarketFooterWrapper>
					<SocialFooterWrapper>
						<SocialFooter>
							<div><a href={"https://www.facebook.com/profile.php?id=100088569573877"}><img src={facebook}/></a></div>
							<div><a href={"https://www.instagram.com/mapanda_app/"}><img src={instagram}/></a></div>
							<div><a href={"viber://chat?number=%2B380961690439"}><img src={viber}/></a></div>
							<div><a href={" https://t.me/MaPanda_admin"}><img src={telegram}/></a></div>
						</SocialFooter>
						<div>
							<Email onClick={sendEmail}><img src={email}/>{emailAddress}</Email>
							<Phone onClick={onPhoneClick}><img src={phone}/>{phoneNumber}</Phone>
						</div>
					</SocialFooterWrapper>
				</FooterContent>

				<CopyWrapper onClick={()=> scroll.scrollTo(scroll.anchors.head)}>© 2023, made with ♥ by MaPanda</CopyWrapper>
			</div>
		</>

	);
};

export default Footer;
