import React from "react";
import styled from "styled-components";
import {UlWrapper} from "../main-styled/MainStyled";

export const MainSectionWrapper = styled.div`
	position: relative;
	width: 100%;
  padding: 100px 12.4% 16% 12.4%;
	box-sizing: border-box;
  font-family: 'MPLUSRounded1c-Regular';
	@media (max-width: 1400px) {
    padding: 60px 7% 350px 7%;
  }
  @media (max-width: 870px) {
    padding: 60px 7% 40% 7%;
  }
 //@media (max-width: 811px) {
 //   padding: 60px 7% 40% 7%;
 // }
 @media (max-width: 392px) {
    padding: 35px 24px 60px 24px;
  }
	@media (max-width: 322px) {
    padding: 15px 24px 30px 24px;
  }
	
`;

export const MainSectionTitle = styled.div`
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.02em;
	color: #0B3D4C;
	text-transform: uppercase;
	width: 46%;
	& span{
    font-weight: 800;
  }
  @media (max-width: 1400px) {
    width: 400px;
    font-size: 40px;
  }
  @media (max-width: 992px) {
    width: 320px;
    font-size: 32px;
    line-height: 41px;
  } 
	@media (max-width: 430px) {
    width: 350px;
  }
	@media (max-width: 362px) {
    font-size: 28px;
  }	
	@media (max-width: 322px) {
    width: 261px;
    font-size: 28px;
    line-height: 36px;
  }
`;

export const SubTitleWrapper = styled.p`
	margin: 20px 0 40px 0;
	padding: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
	color: #0B3D4D;
	width: 330px;
  font-family: 'Mulish-Regular';
  @media (max-width: 1400px) {
    font-size: 18px;
    width: 250px;
  }
  @media (max-width: 1282px) {
    margin: 20px 0 24px 0;
  }
  @media (max-width: 392px) {
    margin: 4px 0 10px 0;
    line-height: 22px;
  }
 	@media (max-width: 322px) {
    margin: 6px 0 2px 0;
    font-size: 16px;
    line-height: 20px;
		width: 220px;
  }
`;

export const Advantages = styled(UlWrapper)`
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.02em;
	color: #2E6778;
	text-transform: uppercase;
	margin-top: 3.7%;
	font-family: 'MPLUSRounded1c-Regular';
  & li{
		display: flex;
		justify-content: left;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
	}
	& img{
		width: 37px;
	}
  @media (max-width: 1400px) {
    margin-top: 3.7%;
  }
 	@media (max-width: 811px) {
    margin-top: 135%;
  }
 	@media (max-width: 768px) {
    margin-top: 155%;
  }
	@media (max-width: 392px) {
    margin-top: 135%;
    & img{
      width: 36px;
    }

  }
	@media (max-width: 322px) {
    margin-top: 166%;
    font-size: 12px;
    line-height: 18px;
    & img{
      width: 28px;
    }
    & li{
      margin-bottom: 8px;

    }

  }
`;

export const ToMarketWrapper = styled.div`
	margin-top: 60px;
	& p {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.02em;
		color: #A29EB6;
		padding-bottom: 17px;
  }
	& div {
		display: flex;
		justify-content: left;
		align-items: center;
		gap: 20px;
		& img {
			width: 72px;
      @media (max-width: 1282px) {
        width: 60px;
      }@media (max-width: 322px) {
        width: 54px;
      }
		}
	}
  @media (max-width: 1400px) {
    margin-top: 23px;
    & p {
      padding-bottom: 12px;
    }
  }


`;

export const BookWrapper = styled.div`
	position: absolute;
  width: 13.2%;
  right: 28%;
  top: -2%;
	& img{
		width: 100%;
	}
  @media (max-width: 1400px) {
    right: 31%;
  }
 @media (max-width: 993px) {
    display: none;
  }
`;

export const PandaWrapper = styled.div`
	position: absolute;
  width: 23.7%;
  left: 42%;
  top: 18%;
  box-sizing: border-box;
  & img{
		width: 100%;
	}
  @media (max-width: 1400px) {
    left: 38.5%;
  }
	@media (max-width: 1282px) {
    top: 16%;
  }
  @media (max-width: 1025px){
    left: 42%;
    top: 20%;
	}
  @media (max-width: 993px){
    left: 40%;
    top: 28%;
	}
  @media (max-width: 916px){
    left: 47%;
    top: 25%;
	}
	@media (max-width: 811px) {
    width: 93%;
    left: 7%;
    top: 38%;
    & img{
      width: 50%;
    }
  }
	@media (max-width: 768px) {
    //width: 93%;
    //left: 7%;
    top: 42%;
    //& img{
    //  width: 50%;
    //}
  }
	@media (max-width: 392px) {
    left: 9%;
    top: 460px;
		width: 250px;
		height: 282px;
    & img{
      width: 71%;
    }
  }
	@media (max-width: 322px) {
    left: 7.4%;
    top: 450px;
  }
`;

export const StarWrapper = styled.div`
	position: absolute;
  width: 53%;
  right: -33%;
  top: 40%;
	& img{
		width: 100%;
	}
  @media (max-width: 798px) {
    width: 20%;
    right: 39%;
    top: 42.5%;
  }
  @media (max-width: 392px) {
    width: 102px;
    right: 15px;
    top: 35%;
  }
`;