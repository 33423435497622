import {getTradeItem} from "../store/store";

export const keyCrmApi = {
    skuCodes: {
        free: '1101',
        lessons_3: 'L3',
        lessons_6: 'L6',
        lessons_12: 'L12',
        lessons_24: 'L24',
    },
    createOrder: async (userName, phone, contactChanel,setUserName, setUserPhone) => {
        const keyCrmUrl = 'https://us-central1-bini-mp-core2-23-0216.cloudfunctions.net/landingKeycrmRequest';

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");

        // извлекаем текущую строку запроса
        const queryParameters = new URLSearchParams(window.location.search)

        // извлекаем UTM метки из запроса
        const utm_source = queryParameters.get("utm_source")
        const utm_medium = queryParameters.get("utm_medium")
        const utm_campaign = queryParameters.get("utm_campaign")
        const utm_term = queryParameters.get("utm_term")
        const utm_content = queryParameters.get("utm_content")

        const payload = {
            "source_id": 10,
            "pipeline_id": 1,
            "manager_comment": contactChanel, // Канал связи из формы
            "utm_source": utm_source,
            "utm_medium": utm_medium,
            "utm_campaign": utm_campaign,
            "utm_term": utm_term,
            "utm_content": utm_content,
            "contact": {
                "full_name": userName, // Имя пользователя из формы
                "phone": phone //контактный номер телефона из формы
            },
            "products": [
                {
                    "sku": getTradeItem().sku, // идентификатор товара
                    "price": getTradeItem().price //цена товара
                }
            ]
        }

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payload),
            redirect: 'follow'
        };

        console.warn(JSON.stringify(requestOptions))

        const response = await fetch(keyCrmUrl, requestOptions)
        setUserName('');
        setUserPhone('');
        return response.ok;

    }
}