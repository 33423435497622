import React, {useEffect, useState} from 'react';
import {SubjectsHeaderWrapper} from "../subjects-section/SubjectsStyles";
import sale from "../img/полоска.png";
import lin from "../img/Vector 357.png";
import linSm from "../img/Vector 3572.png";
import {
	GreenWrDiv, GreenWrDivOpt,PricePrWhite,
	PriceAll,
	PriceBoxesWr,
	PricePrBlack,
	PriceTryFree
} from "./PriceStyled";
import TryFree from "../try-free/TryFree";
import PriceBoxItem from "./PriceBoxItem";
import {scroll} from "../../middleware/scroll";
import {keyCrmApi} from "../../middleware/api/apiKeyCrm";
import {useTranslation} from "react-i18next";

const Price = () => {
	const { t } = useTranslation();
	return (
		<PriceAll id={scroll.anchors.prices}>
			<SubjectsHeaderWrapper>{t('cost')} </SubjectsHeaderWrapper>
			<PriceBoxesWr>
				<PriceBoxItem price={t('cost-card.price.two')}
											count={t('cost-card.count.two')}
											countCost={t('cost-card.count-cost.two')}
											info={t('cost-card.info.two')}
											// sale={sale}
											// line={linSm}
											// salePercent={t('cost-card.sale.one')}
											PricePrGr={PricePrWhite}
											sku={keyCrmApi.skuCodes.lessons_3}/>

				<GreenWrDiv>
					<GreenWrDivOpt>{t('cost-card.optimal')}</GreenWrDivOpt>
				<PriceBoxItem price={t('cost-card.price.three')}
											count={t('cost-card.count.three')}
											countCost={t('cost-card.count-cost.three')}
											info={t('cost-card.info.three')}
											sale={sale}
											line={lin}
											salePercent={t('cost-card.sale.one')}
											PricePrGr={PricePrBlack}
							  				sku={keyCrmApi.skuCodes.lessons_6}/>
				</GreenWrDiv>
				<PriceBoxItem price={t('cost-card.price.four')}
											count={t('cost-card.count.four')}
											countCost={t('cost-card.count-cost.four')}
											info={t('cost-card.info.four')}
											sale={sale}
											salePercent={t('cost-card.sale.two')}
											line={lin}
											PricePrGr={PricePrBlack}
							  				sku={keyCrmApi.skuCodes.lessons_12}/>
			<PriceBoxItem price={t('cost-card.price.five')}
											count={t('cost-card.count.five')}
											countCost={t('cost-card.count-cost.five')}
											info={t('cost-card.info.four')}
											sale={sale}
											salePercent={t('cost-card.sale.three')}
											line={lin}
											PricePrGr={PricePrBlack}
							  				sku={keyCrmApi.skuCodes.lessons_24}/>
			</PriceBoxesWr>
			<PriceTryFree><TryFree/></PriceTryFree>
		</PriceAll>
	);
};

export default Price;