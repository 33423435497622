import React from 'react';
import {
	MaPandaInfo,
	MaPandaWr, MaPandersBorn,
	MaPandersBottom, MaPandersCatLeft,
	MaPandersCatLeftOne, MaPandersCatRight,
	MaPandersCatRightTwo,
	MaPandersFotoBorder,
	MaPandersFotoBorderTwo,
	MaPandersHorse,
	MaPandersSpecHead,
	MaPandersTop,
	MaPandersWr
} from "./MaPandersStyled";

import line from "../img/Vector 300.png";
import maPan1 from "../img/Rectangle1.png";
import maPan2 from "../img/Rectangle2.png";
import maPan3 from "../img/Rectangle3.png";
import maPan4 from "../img/Rectangle4.png";
import maPan5 from "../img/Rectangle5.png";
import maPan6 from "../img/Rectangle6.png";
import born from "../img/rog.png";
import horse from "../img/hors.png";
import catL from "../img/cat-left.png";
import catR from "../img/cat-right.png";
import cat1 from "../img/cat 1.png"
import cat2 from "../img/cat 2.png"
import {scroll} from "../../middleware/scroll";
import {useTranslation} from "react-i18next";
const MaPanders = () => {
	const { t } = useTranslation();
	return (
		<div className="ma-panders-bg-line" id={scroll.anchors.specialists}>
			<div className="ma-panders-bg-left">
				<MaPandaWr>
					Mapanda
					<MaPandersSpecHead>
						{t('specialist')}
						<img src={line}/>
					</MaPandersSpecHead>
				</MaPandaWr>
				<MaPandaInfo>{t('specialist-info-first')}</MaPandaInfo>
				<MaPandaInfo>{t('specialist-info-second')}</MaPandaInfo>
			</div>
			<div className="ma-panders-bg-right">
				<MaPandersWr>
					<MaPandersTop>
						<li>
							<img src={maPan1}/>
							<MaPandersFotoBorder></MaPandersFotoBorder>
						</li>
						<li>
							<img src={maPan3}/>
							<MaPandersBorn><img src={born}/></MaPandersBorn>
							<MaPandersHorse><img src={horse}/></MaPandersHorse>
						</li>
						<li>
							<img src={maPan5}/>
							<MaPandersFotoBorderTwo></MaPandersFotoBorderTwo>
						</li>
					</MaPandersTop>
					<MaPandersBottom>
						<li>
							<img src={maPan2}/>
							<MaPandersCatLeft><img src={catL}/></MaPandersCatLeft>
							<MaPandersCatRight><img src={catR}/></MaPandersCatRight>
						</li>
						<li>
							<img src={maPan4}/>
							<MaPandersFotoBorder></MaPandersFotoBorder>
						</li>
						<li>
							<img src={maPan6}/>
							<MaPandersCatLeftOne><img src={cat1}/></MaPandersCatLeftOne>
							<MaPandersCatRightTwo><img src={cat2}/></MaPandersCatRightTwo>
						</li>
					</MaPandersBottom>
				</MaPandersWr>
			</div>
		</div>
	);
};

export default MaPanders;