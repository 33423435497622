import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {FormSubmitBtn} from "./FormSubmitBtnStyled";
import {keyCrmApi} from "../../middleware/api/apiKeyCrm";
import {useNavigate} from "react-router-dom";

const FormSubmit = ({userName, userPhone, contact, validateFields, setUserName, setUserPhone,selectedCountryCode}) => {

	const { t } = useTranslation();

	const phone = `+${selectedCountryCode}${userPhone}`


	const lang =  t('lang');
	const navigate = useNavigate();
	const handleTryFreeClick = () => {

		if (validateFields()) {
			// Действия при успешной валидации
			keyCrmApi.createOrder(userName, phone, contact,setUserName, setUserPhone);
			if (lang === 'en'){
				navigate("/en/order");
			} else {
				navigate("/ua/order");
			}
		} else {
			// Действия при ошибке валидации
		}
	}

	return (
		<FormSubmitBtn>
			<div className="try-btn" onClick={handleTryFreeClick}>{t('send')}</div>
		</FormSubmitBtn>
	);
};

export default FormSubmit;