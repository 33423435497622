import React, {useState} from 'react';
import NavBody from "./NavBody";

import logo from "../img/Logo-small.png";
import appStore from "../img/App_Store button.png";
import playMarket from "../img/google play.png";
import viber from "../img/viber.png";
import telegram from "../img/telegram.png";
import instagram from "../img/Instagram_logo_2016 1.png";
import facebook from "../img/facebook.png";
import close from "../img/Union.png";
import arr from "../img/arrGray.png"

import {
	CloseBtn,
	LogoWrapper,
	MarketWrapper,
	Menu, MenuContent, NavBodyBurger,
	NavBurgerWrapper, Social
} from "./HeaderStyled";

const NavBurger = () => {

	const [showMenu, setShowMenu] = useState(false);

	const handlerOpenMenu = () => {
		setShowMenu(!showMenu);
	}

	return (

		<NavBurgerWrapper>
			<Menu onClick={handlerOpenMenu}>
				<div></div>
				<div></div>
			</Menu>
			{showMenu &&
				<MenuContent onClick={handlerOpenMenu}>
					<CloseBtn>
						<img src={close}/>
					</CloseBtn>
					<LogoWrapper>
						<img src={logo}/>
					</LogoWrapper>
					<NavBodyBurger>
						<NavBody arr={arr} showMenu={showMenu}/>
					</NavBodyBurger>
					<MarketWrapper>
						<div><a href={"https://apps.apple.com/us/app/mapanda/id1671497001"}><img src={appStore}/></a></div>
						<div><a href={"https://play.google.com/store/apps/details?id=com.mapanda.mp.learning"}><img src={playMarket}/></a></div>
					</MarketWrapper>
					<Social>
						<div><a href={"https://www.facebook.com/profile.php?id=100088569573877"}><img src={facebook}/></a></div>
						<div><a href={"https://www.instagram.com/mapanda_app/"}><img src={instagram}/></a></div>
						<div><a href={"viber://chat?number=%2B380961690439"}><img src={viber}/></a></div>
						<div><a href={" https://t.me/MaPanda_admin"}><img src={telegram}/></a></div>
					</Social>
				</MenuContent>
			}
		</NavBurgerWrapper>
	);
};

export default NavBurger;