import React from 'react';
import {
	LinePriseWr,
	PriceBox,
	PriceBoxGrayBg,
	PriceBoxGreen,
	PriceBoxGreenWr,
	PriceBoxHeader,
	PricePr, SalePercent,
	SaleWr
} from "./PriceStyled";
import check from "../img/Vector 234 (Stroke).png";

import PriceBtn from "./PriceBtn";

const PriceBoxItem = ({count, price, countCost, info, sale,PricePrGr, line, sku, salePercent}) => {

	return (
		<PriceBox>
			<SaleWr>
				<div style={{position: "relative"}}>
					<SalePercent>{salePercent}</SalePercent>
				</div>
				<img src={sale}/>
			</SaleWr>
			<PriceBoxHeader>{count}</PriceBoxHeader>
			<PriceBoxGrayBg>
				<PricePr>{price}</PricePr>
				<PricePrGr>{countCost}
					<LinePriseWr><img src={line}/></LinePriseWr>
				</PricePrGr>
				<PriceBoxGreenWr>
					<img src={check}/>
					<PriceBoxGreen>{info}</PriceBoxGreen>
				</PriceBoxGreenWr>
				<PriceBtn sku={sku} price={price}/>
			</PriceBoxGrayBg>
		</PriceBox>
	);
};

export default PriceBoxItem;