import React, {useEffect} from "react"
import { useState, useRef } from "react"
import playBtn from "../img/play button.png";
import {PlayBtn, VideoStyle, VideoWrapper} from "./VidepSectionStyled";
import preloader from "../img/preloader.png";
import {useTranslation} from "react-i18next";
import videoUkBig from "./video/mp_v002_1920x1080_ua_nb_69s.mp4";
import videoEnBig from "./video/mp_v002_1920x1080_en_nb_63s.mp4";
import videoUkSmall from "./video/mp_v002_1080x1920_ua_nb_69s_mob.mp4";
import videoEnSmall from "./video/mp_v002_1080x1920_en_nb_63s_mob.mp4";

const Video = ({ ...props }) => {

	const {t} = useTranslation();
	// const video = require(t('video-url'))
	const [videoEn, setVideoEn] = useState(videoEnSmall);
	const [videoUk, setVideoUk] = useState(videoUkSmall);

	const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);

	useEffect(()=> {
		if (isMobileDevice){
			setVideoEn(videoEnSmall);
			setVideoUk(videoUkSmall);
		} else {
			setVideoEn(videoEnBig);
			setVideoUk(videoUkBig);
		}
		setStart(true);
	},[isMobileDevice]);

	const video = t('lang')==="en" ? videoEn : videoUk;


	const [isPlaying, setIsPlaying] = useState(false);
	const [videoLoaded, setVideoLoaded] = useState(false);
	const videoRef = useRef(null);
	const videoWrapperRef = useRef(null);

	const [start, setStart] = useState(false);

	useEffect(() => {
		if (start){
			if (videoRef.current) {
				const videoElement = videoRef.current;

				const handleLoadedData = () => {

					setVideoLoaded(true);
				};

				const handlePlay = () => {
					setIsPlaying(true);
				};

				const handlePause = () => {
					setIsPlaying(false);
				};

				videoElement.addEventListener('play', handlePlay);
				videoElement.addEventListener('pause', handlePause);
				videoElement.addEventListener('loadeddata', handleLoadedData);

				return () => {
					videoElement.removeEventListener('play', handlePlay);
					videoElement.removeEventListener('pause', handlePause);
					videoElement.removeEventListener('loadeddata', handleLoadedData);
				};
			}
		}
	}, [start]);


	const playPauseVideo = () => {
		const videoElement = videoRef.current;

		if (videoElement) {
			if (isPlaying) {
				videoElement.pause();
			} else {
				videoElement.play();
			}
		}
	};


	return (
		<>
			<VideoWrapper ref={videoWrapperRef}  onClick={playPauseVideo}>
				<VideoStyle
					{...props}
					ref={videoRef}
					poster={preloader}
				>
					{start && <source src={video}/>}
				</VideoStyle>
				{ (videoLoaded || isMobileDevice) && (
					<PlayBtn>
						{isPlaying ? '' : <img src={playBtn} alt={''}/>}
					</PlayBtn>
				)}
			</VideoWrapper>
	</>

	)
}



export default Video ;
