import React from 'react';
import {
	BgPopUpBottom,
	BlueDecoPopUp,
	CharacterPopUp, CloseBtnPopUp, HeaderPopUp, LightBlueDecoPopUp, MarketWrapperPopUp,
	OrangeDecoPopUp,
	PinkDecoPopUp, PlayWrPopUp,
	PopUpContent,
	PopUpWrapper, SmallGreenDecoPOpUp, SmallGreenDecoPOpUpS, TextPopUp
} from "./SendFormStyled";
import deco1 from "../img/decoorangepopup.png";
import deco2 from "../img/Decopinkpopup.png";
import deco3 from "../img/bluepopap.png";
import deco4 from "../img/lightblupopup.png";
import deco5 from "../img/smallgreenpopup.png";
import char from "../img/Character.png";
import btn from "../img/closeBtn.png";
import app from "../img/App_Store button.png";
import play from "../img/google play.png";
import bgBot from "../img/bgpopupbottom.png";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
const PopUp = () => {
	const { t } = useTranslation();

	const lang =  t('lang');
	const navigate = useNavigate();
	const closePopUp = () => {
			if (lang === 'en'){
				navigate("/en");
			} else {
				navigate("/ua");
			}
	}

	return (
		<PopUpWrapper>
				<PopUpContent className="pop-up-bg">
						<CharacterPopUp><img src={char}/></CharacterPopUp>
						<CloseBtnPopUp onClick={closePopUp}><img src={btn}/></CloseBtnPopUp>
						<OrangeDecoPopUp><img src={deco1}/></OrangeDecoPopUp>
						<PinkDecoPopUp><img src={deco2}/></PinkDecoPopUp>
						<BlueDecoPopUp><img src={deco3}/></BlueDecoPopUp>
						<LightBlueDecoPopUp><img src={deco4}/></LightBlueDecoPopUp>
						<SmallGreenDecoPOpUp><img src={deco5}/></SmallGreenDecoPOpUp>
						<SmallGreenDecoPOpUpS><img src={deco5}/></SmallGreenDecoPOpUpS>
						<HeaderPopUp>{t('pop-up.header')}</HeaderPopUp>
						<TextPopUp>
							<span>{t('pop-up.top-line')}</span>
							{t('pop-up.bottom-line')}
						</TextPopUp>
						<MarketWrapperPopUp>
							<div>
								<a href={"https://apps.apple.com/us/app/mapanda/id1671497001"}><img src={app}/></a>
							</div>
							<PlayWrPopUp><a href={"https://play.google.com/store/apps/details?id=com.mapanda.mp.learning"}><img src={play}/></a></PlayWrPopUp>
						</MarketWrapperPopUp>
					<BgPopUpBottom><img src={bgBot}/></BgPopUpBottom>
				</PopUpContent>
		</PopUpWrapper>
	);
};

export default PopUp;