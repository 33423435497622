import React, {useEffect, useState} from 'react';
import {
	PeculiaritiesStyleFiveSmall,
	PeculiaritiesStyleText,
} from "./PeculiaritiesStyled";
import star from "../img/yellow_star Image.png";
import {useTranslation} from "react-i18next";

const PeculiaritiesInfo = ({InfoWrapper, PeculiaritiesStyle, text, isFive, TestWrapper, StarWrapper}) => {

	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(()=>{
		setWidth(window.innerWidth )
	},[window.innerWidth])

	return (
		<InfoWrapper>
			<TestWrapper style={width < 992 && isFive ? PeculiaritiesStyleFiveSmall : PeculiaritiesStyle} className={"ggggggggggggggggggg"}>
				<StarWrapper isFive={isFive ? isFive : null}>
					<img src={star}/>
				</StarWrapper>
				<PeculiaritiesStyleText>{t(`${text}`)}</PeculiaritiesStyleText>
			</TestWrapper>
		</InfoWrapper>
	);
};

export default PeculiaritiesInfo;