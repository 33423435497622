import React, { useState } from 'react';

import {
	ImgDecorationWr,
	ImgDecorationWr1,
	ImgDecorationWr2,
	ImgDecorationWr3,
	ImgDecorationWr4,
	SendFormChar,
	SendFormCheck, SendFormContainer, SendFormFirstBg,
	SendFormForm, SendFormFormTryFree,
	SendFormGrayWr,
	SendFormHeaderWrapper,
	SendFormInfo, SendFormSecondBg,
	SendFormWr
} from "./SendFormStyled";

import char from "../img/Character.png";
import pur from "../img/pur.png";
import orange from "../img/deco 1.png";
import pink from "../img/Deco 2.png";
import darkBlue from "../img/Ellipse 85.png";
import blue from "../img/Ellipse 86.png";
import green from "../img/Ellipse 87.png";
import FormSubmit from "../form-submit-button/FormSubmit";
import {scroll} from "../../middleware/scroll";
import {useTranslation} from "react-i18next";
import PhoneNumberInput from "./PhoneNumberInput";

const SendForm = () => {

	const {t} = useTranslation();
	const [userName, setUserName] = useState("");
	const [userPhone, setUserPhone] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [desiredContact, setDesiredContact] = useState("Phone Call");
	const [className, setClassName] = useState('');
	const [classNamePh, setClassNamePh] = useState('');
	const [selectedCountryCode, setSelectedCountryCode] = useState('');
	const validateFields = () => {
		let isValid = true;

		// Проверка поля имени

		if(userEmail.length > 0){
			isValid=false;
		} else {
			isValid=true;
		}

		const reN = /^[\p{L} -]{2,20}$/u
		if (userName.trim() === '' || !reN.test(userName.trim())) {
			// setNameError(t('name-validate'));
			setClassName('input-error');
			isValid = false;
			// setIsValid(false);
		} else {
			// setNameError('');
			setClassName('')
		 }
	 // Проверка поля телефона

		const re = /^[+]?[0-9]{1,20}$/
		if (userPhone.trim() === '' || !re.test(userPhone.trim()) || userPhone.length < 9) {
			// setPhoneError(t('phone-validate'));
			setClassNamePh("top-mr input-error");
			isValid = false;
		} else {
			// setPhoneError('');
			setClassNamePh('');
		}

		return isValid;
	};
	const handleRadioButtonChange = (event) => {
		setDesiredContact(event.target.value);
	};

	return (
		<SendFormContainer>
			<SendFormFirstBg></SendFormFirstBg>
			<SendFormSecondBg></SendFormSecondBg>
			<SendFormWr id={scroll.anchors.sendForm}>
				<ImgDecorationWr>
					<img src={orange}/>
				</ImgDecorationWr>
				<ImgDecorationWr1>
					<img src={pink}/>
				</ImgDecorationWr1>
				<ImgDecorationWr2>
					<img src={darkBlue}/>
				</ImgDecorationWr2>
				<ImgDecorationWr3>
					<img src={blue}/>
				</ImgDecorationWr3>
				<ImgDecorationWr4>
					<img src={green}/>
				</ImgDecorationWr4>
				<SendFormHeaderWrapper>{t('form-header')} </SendFormHeaderWrapper>
				<SendFormInfo>{t('form-info')} </SendFormInfo>
				<SendFormGrayWr>
					<SendFormForm>
						<input
							type="text"
							placeholder={t('name-form')}
							className={className}
							value={userName}
							onChange={(ev) => {
								setUserName(ev.target.value);
								// setNameError('');
							}}
						/>
						<input type="email" name="hiddenField" style={{display: "none"}} onChange={(ev) => {
							setUserEmail(ev.target.value);
						}}/>
						<PhoneNumberInput classNamePh={classNamePh}
															placeholder={t('phone-number')}
															userPhone={userPhone}
															setSelectedCountryCode={setSelectedCountryCode}
															setUserPhone={setUserPhone}/>
						<SendFormFormTryFree>
							<FormSubmit  userPhone={userPhone.trim()}
													 userName={userName.trim()}
													 contact={desiredContact}
													 setUserName={setUserName}
													 setUserPhone={setUserPhone}
													 selectedCountryCode={selectedCountryCode}
													 validateFields={validateFields}/>
						</SendFormFormTryFree>

					</SendFormForm>
					<SendFormCheck>
						<li>{t('form-checked-header')}</li>
						<li>
							<label>
								<div>
									<input
										type="radio"
										value="Phone Call"
										checked={desiredContact === "Phone Call"}
										onChange={handleRadioButtonChange}
									/>
								</div>
								<span>{t('form-checked.one')}</span>
							</label>
						</li>
						<li>
							<label>
								<div>
									<input
										type="radio"
										value="Viber"
										checked={desiredContact === "Viber"}
										onChange={handleRadioButtonChange}
									/>
								</div>
								<span>{t('form-checked.two')}</span>
							</label>
						</li>
						<li>
							<label>
								<div>
									<input
										type="radio"
										value="Telegram"
										checked={desiredContact === "Telegram"}
										onChange={handleRadioButtonChange}
									/>
								</div>
								<span>{t('form-checked.three')}</span>
							</label>
						</li>
						{desiredContact !== "Phone Call" ? (
							<li>
								<div>
									<div>
										<img src={pur}/>
									</div>
								</div>
								{t('form-checked-info')}
							</li>
						) : (
							<li>

							</li>
						)}
					</SendFormCheck>
				</SendFormGrayWr>
				<SendFormChar>
					<img src={char}/>
				</SendFormChar>
			</SendFormWr>
		</SendFormContainer>
	);
};

export default SendForm;
