import React from 'react';
import {useTranslation} from "react-i18next";
import SelectLanguage from "./SelectLanguage";
import {scroll} from "../../middleware/scroll";

const NavBody = ({arr, showMenu}) => {


	const { t } = useTranslation();

	return (
		<>
			<li onClick={()=> scroll.scrollTo(scroll.anchors.disciplines)}>{t('disciplines')}</li>
			<li onClick={()=> scroll.scrollTo(scroll.anchors.prices)}>{t('prices')}</li>
			<li onClick={()=> scroll.scrollTo(scroll.anchors.aboutUs)}>{t('about-us')}</li>
			<li onClick={()=> scroll.scrollTo(scroll.anchors.specialists)}>{t('specialists')}</li>
			{!showMenu && <SelectLanguage arr={arr}/>}
		</>
	);
};

export default NavBody;