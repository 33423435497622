import React from 'react';
import {useTranslation} from "react-i18next";
import TryFree from "../try-free/TryFree";
import check from "../img/Subtract-l.png";
import appStore from "../img/App_Store button-p.png";
import playMarket from "../img/google play-p.png";
import book from "../img/book.png";
import panda from "../img/KA.png";
import star from "../img/star.png";
import {
	Advantages, BookWrapper,
	MainSectionTitle,
	MainSectionWrapper, PandaWrapper, StarWrapper,
	SubTitleWrapper, ToMarketWrapper
} from "./MainSectionStyled";

const MainSection = () => {

	const { t } = useTranslation();

	return (
		<MainSectionWrapper>
			<BookWrapper><img src={book}/></BookWrapper>
			<PandaWrapper>
				<div style={{position:'relative'}}><img src={panda}/>
					<StarWrapper><img src={star}/></StarWrapper>
				</div>
			</PandaWrapper>
			<MainSectionTitle>{t('title-main-light')} <span>{t('title-main-strong')}</span></MainSectionTitle>
			<SubTitleWrapper>{t('sub-title-main')}</SubTitleWrapper>
			<TryFree/>
			<Advantages>
				<li><img src={check}/>{t('advantages.one')}</li>
				<li><img src={check}/>{t('advantages.two')}</li>
				<li><img src={check}/>{t('advantages.three')}</li>
			</Advantages>
			<ToMarketWrapper>
				<p>{t('download')}</p>
				<div>
					<a href={"https://apps.apple.com/us/app/mapanda/id1671497001"}><img src={appStore}/></a>
					<a href={"https://play.google.com/store/apps/details?id=com.mapanda.mp.learning"}><img src={playMarket}/></a>
				</div>
			</ToMarketWrapper>
		</MainSectionWrapper>
	);
};

export default MainSection;