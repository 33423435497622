import './App.css';
import MainContainer from "./components/MainContainer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PopUp from "./components/send-form/PopUp";

function App() {
  document.querySelectorAll('*').forEach(el => {
    if (el.offsetWidth > document.documentElement.offsetWidth) {
      console.log('Found the worst element ever: ', el);
    }
  });

  // const currentUrl = window.location.pathname;
  // console.log(currentUrl);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/en" element={<MainContainer/>}/>
        <Route path="/ua" element={<MainContainer/>}/>
        <Route path="/ua/order" element={<PopUp/>}/>
        <Route path="/en/order" element={<PopUp/>}/>
        <Route path="/" element={<MainContainer/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
