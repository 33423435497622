import React from "react";
import styled from "styled-components";
import bgIm from "../img/background-sky.png";
import leftIm from "../img/1.png";
import rightIm from "../img/2.png";
import centerIm from "../img/5.png";
export const PeculiaritiesWrapperStyle = {
	background: `url(${bgIm})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
}
export const PeculiaritiesCont = styled.div`
	overflow: hidden;
	@media(max-width: 392px){
		margin-top: 50px;
	}
	@media(max-width: 322px){
		margin-top: 68px;
	}
`;
export const PeculiaritiesWrapperStyleWrapper = styled.div`
		height: 1320px;
		width: 100%;
		position: relative;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 70px;
  @media (max-width: 1600px) {
    height: 1241px;
  }

	@media (max-width: 993px) {
    height: 1370px;
    margin-top: 70px;
		align-items: end;
  }
	@media (max-width: 769px) {
    height: 1420px;
  }
	
	@media (max-width: 435px) {
		height: 1680px;
  }
	@media (max-width: 392px) {
		margin-top: 30px;
    height: 1500px;
  }
	`;


export const PeculiaritiesStyle = {
	background: `url(${leftIm})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	backgroundPosition: 'center',
}
export const LeftWrapper = styled.div`
  height: 370px;
	width: 532px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 9%;
	@media (max-width: 1600px){
  	height: 320px;
  	width: 452px;
}
  @media (max-width: 992px){
    height: 270px;
    width: 370px;
  }
`;
export const RightWrapper = styled(LeftWrapper)`
  padding-top: 11%;
`;
export const PeculiaritiesStyleTwo = {
	background: `url(${rightIm})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	backgroundPosition: 'center',
}
export const PeculiaritiesStyleFive = {
	background: `url(${centerIm})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	backgroundPosition: 'center',
}
export const PeculiaritiesStyleFiveSmall = {
	background: `url(${leftIm})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	backgroundPosition: 'center',
}
export const CenterWrapper = styled(LeftWrapper)`
  padding-top: 0%;
	@media (max-width: 992px){
    padding-top: 5%;
}
	@media (max-width: 435px){
    padding-top: 10%;
}
`;
export const PeculiaritiesStyleInfoWrapper = styled.div`
  position: absolute;
  left: 9%;
  top: 115px;
	z-index: 10000;
  @media (max-width: 1282px){
    left: 5%;
    top: 90px;
  }
  @media (max-width: 1240px){
		top: 9%;
  }
  // @media (max-width: 1025px){
	//	top: 9%;
  //}
  @media (max-width: 993px){
		top: 2%;
  }
	@media (max-width: 435px){
    left: -10px;
    top: 0px;
  }
	@media (max-width: 392px){
    left: -20px;
  }
`;
export const PeculiaritiesStyleInfoWrapperTwo = styled.div`
  position: absolute;
  right: 17.3%;
  top: 105px;
  @media (max-width: 1282px){
    right: 13%;
    top: 55px;
  }
	@media (max-width: 1240px){
    right: 3%;
    top: 40px;
  }
	//@media (max-width: 1025px){
  //  right: 3%;
  //  top: 40px;
  //}
	@media (max-width: 993px){
    right: 5%;
    top:150px;
  }
	@media (max-width: 435px){
    right: -10px;
    top: 200px;
  }
	@media (max-width: 392px){
    right: -20px;
  }
`;
export const PeculiaritiesStyleInfoWrapperThree = styled.div`
  position: absolute;
  left: 17%;
  bottom: 320px;
  @media (max-width: 1282px){
    left: 10%;
    bottom: 215px;
  } 
	@media (max-width: 1240px){
    left: -20px;
    bottom: 350px;
  }
//@media (max-width: 1025px){
//    left: -20px;
//    bottom: 350px;
//  }
	@media (max-width: 993px){
    left: 5%;
    top: 270px;
  }
	@media (max-width: 435px){
    left: -10px;
    top: 410px;
  }
	@media (max-width: 392px){
    left: -20px;
  }
`;
export const PeculiaritiesStyleInfoWrapperFour = styled.div`
  position: absolute;
  right: 9.2%;
  top: 420px;
  @media (max-width: 1282px){ 
		right: 7%;
    top: 490px;
  }
	@media (max-width: 1240px){ 
		right: 0;
    top: 450px;
  }
//@media (max-width: 1025px){ 
//		right: 0;
//    top: 450px;
//  }
	@media (max-width: 993px){ 
		right: 5%;
    top: 420px;
  }
	@media (max-width: 435px){ 
		right: -10px;
    top: 610px;
  }
	@media (max-width: 392px){ 
		right: -20px;
  }
`;
export const PeculiaritiesStyleInfoWrapperFive = styled.div`
  position: absolute;
  right: 32%;
  bottom: 60px;
  @media (max-width: 1282px){
    right: 25%;
    bottom: 70px;
  } 
	@media (max-width: 993px){
    left: 5%;
    bottom: 480px;
  }
	@media (max-width: 769px){
    bottom: 550px;
  }
	@media (max-width: 435px){
    left: -10px;
  }
	@media (max-width: 392px){
    left: -20px;
    bottom: 380px;
  }
`;

export const PeculiaritiesStyleText = styled.p`
	font-family: 'Mulish-Regular';
	font-size: 24px;
	font-weight: 800;
	line-height: 30px;
	letter-spacing: 0.02em;
	width: 336px;
	text-align: center;
  color: #0B3D4C;
	display: flex;
  @media(max-width: 1600px){
    font-size: 18px;
    line-height: 23px;
		width: 263px;
  } 
	@media(max-width: 992px){
    font-size: 16px;
    line-height: 20px;
		width: 225px;
  }
`;

export const CharacterWrapper = styled.div`
	margin-bottom: 185px;
	width: 24%;
	& img{
		width: 100%;
	}

  @media (max-width: 1282px) {
    margin-bottom: 40px;
    width: 28%;
		margin-right: 50px;
  }
	@media (max-width: 993px) {
    margin-bottom: 150px;
    height: 480px;
    width: 359px;
    margin-right: 0px;
  }
	@media (max-width: 435px) {
    margin-bottom: 130px;
  }
	@media (max-width: 392px) {
    margin-bottom: -40px;
		display: flex;
		justify-content: center;
    & img{
      width: 236px;
			height: 317px;
    }
  }

`;

export const StarWrapper = styled.div`
  height: 118px;
  width: 121px;
	position: absolute;
  left: ${props => props.isFive ? '39%' : '33%'};
  top: ${props => props.isFive ? '-12px' : '60px'};
	& img{
		width: 100%;
	}
	@media(max-width: 1600px){
    height: 95px;
    width: 98px;
  }
	@media(max-width: 1282px){
    top: ${props => props.isFive ? '-2px' : '60px'};
  }	
	@media(max-width: 992px){
    left: ${props => props.isFive ? '33%' : '33%'};
    top: ${props => props.isFive ? '40px' : '40px'};
  }
`;
export const StarWrapperRight = styled(StarWrapper)`
  left: 40%;
  top: 85px;
	transform: rotateY(-180deg);
	@media(max-width: 992px){
    left: 40%;
    top: 50px;
  }
`;

export const PeculiaritiesHeaderWrapper= styled.p`
	display: flex;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
  font-family: 'MPLUSRounded1c-Regular';
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  color: #0B3D4C;
  align-items: center;
  margin-top: 200px;
  & img {
    margin-left: 0;
  }
	@media (max-width: 1513px){
		margin-top: 150px;
	}	
	@media (max-width: 1285px){
		margin-top: 130px;
	}	
	@media (max-width: 992px){
		margin-left: inherit;
		margin-bottom: 0px;
    margin-top: 70px;
    font-size: 32px;
    line-height: 42px;
    flex-flow: column-reverse wrap;
  }
  @media (max-width: 435px){
    letter-spacing: 0.02em;
		padding: 0 10%;
  }
 	@media (max-width: 392px){
		padding: 0 10%;
   	margin-bottom: 10px;
    margin-top: 50px;
  }
 
`;