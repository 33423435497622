import React from 'react';
import Video from "./Video";
import char from "../img/Characters.png";
import {
	VideoCharacterInfoWr,
	VideoSectionImgWr,
	VideoSectionInfo,
	VideoSectionInfoHeader,
	VideoSectionInfoInfo, VideoSectionInfoInfoTwo
} from "./VidepSectionStyled";
import TryFree from "../try-free/TryFree";
import {useTranslation} from "react-i18next";
import GetFreeBtn from "../try-free/GetFreeBtn";

const VideoSection = () => {

	const { t } = useTranslation();

	return (
		<>
			<div className="left-bg-video">
				<VideoCharacterInfoWr>
					<VideoSectionImgWr>
						<img src={char}/>
					</VideoSectionImgWr>
					<div>
						<div className="video-section-bg-info">
							<VideoSectionInfo>
								<VideoSectionInfoHeader>{t('video-section-info-header')}</VideoSectionInfoHeader>
								<VideoSectionInfoInfo>{t('video-section-info')}</VideoSectionInfoInfo>
								<VideoSectionInfoInfoTwo>{t('video-section-info-two')}</VideoSectionInfoInfoTwo>
								<GetFreeBtn/>
							</VideoSectionInfo>
						</div>
					</div>
				</VideoCharacterInfoWr>
			</div>
			<div className="right-bg-video"></div>
			<Video/>
		</>
	);
};

export default VideoSection;