import React from "react";
import styled from "styled-components";

export const SendFormContainer = styled.div`
  position: relative;
  width: 75%;
  height: 820px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1282px) {
    width: 1080px;
    height: 693px;
  }
  @media (max-width: 992px) {
    min-height: 1008px;
    width: calc(100% - 40px);
    margin: 0 15px;
  }
  @media (max-width: 435px) {
    margin: 0 auto;
    min-height: 899px;
  }
  @media (max-width: 394px) {
    width: 100%;
		overflow: hidden;
  }
 @media (max-width: 322px) {
   min-height: 850px;
  }
`;
export const SendFormWr = styled.div`
  width: 100%;
  max-height: 820px;
  background-color: #FFFFFF;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
  box-sizing: border-box;
  padding: 74px 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  @media (max-width: 1600px) {
    width: 1080px;
    padding: 60px 40px;
    height: 693px;
  }
 @media (max-width: 1025px) {
    width: 1000px;
    padding: 60px 40px;
    height: 693px;
	 	left: 12px;
  }
  @media (max-width: 993px) {
    height: 1008px;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 1100px;
    left: initial;
  }
  @media (max-width: 435px) {
    height: 810px;
  }
 @media (max-width: 322px) {
    height: 743px;
	 	width: 297px;
	 margin-left: 10px;
  }
`;

export const SendFormFirstBg = styled.div`
  position: absolute;
  width: 100%;
  height: 685px;
  right: -30px;
  top: 0;
  background: #FFFFFF;
  opacity: 0.4;
  border-radius: 40px;
  transform: matrix(1, -0.07, 0.06, 1, 0, 0);
	
  @media (max-width: 1600px) {
    height: 597px;
    width: 1052px;
    transform: matrix(1, -0.08, 0.05, 1, 0, 0);
    right: 90px;
  }
  @media (max-width: 1367px) {
    right: -25px;
  }

  @media (max-width: 993px) {
    width: 100%;
    right: 0px;
    height: 100%;
  }
  @media (max-width: 435px) {
    height: 810px;
    transform: matrix(1, 0.03, -0.04, 1, 0, 0);
  }
  @media (max-width: 322px) {
    height: 744px;
    transform: matrix(1, 0.03, -0.05, 1, 0, 0);
    width: 320px;
  }
`;
export const SendFormSecondBg = styled.div`
  position: absolute;
  width: 100%;
  height: 703px;
  left: -25px;
  bottom: 0px;
  background: #FFFFFF;
  opacity: 0.4;
  border-radius: 40px;
  transform: matrix(1, -0.06, 0.05, 1, 0, 0);
  @media (max-width: 1600px) {
    width: 1094px;
    height: 573px;
    left: -25px;
    bottom: 130px;
    transform: matrix(1, -0.07, 0.04, 1, 0, 0);
  }
  @media (max-width: 1282px) {
    bottom: 0;
  }
  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
    left: 0px;
  }
  @media (max-width: 435px) {
    height: 810px;
		top: 0;
    transform: matrix(1, -0.01, 0.02, 1, 0, 0);
  } 
	@media (max-width: 322px) {
    height: 744px;
    transform: matrix(1, -0.01, 0.02, 1, 0, 0);
		width: 320px;
  }
`;

export const SendFormHeaderWrapper = styled.div`
  text-transform: uppercase;
  color: #0B3D4C;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: 0.02em;
  text-align: center;

  @media (max-width: 1600px) {
    font-size: 40px;
    line-height: 52px;
  }
  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 48px;
    max-width: 350px;
    margin-top: 261px;
  }
 @media (max-width: 435px) {
    margin-top: 60px;
  }
 @media (max-width: 322px) {
    margin-top: 39px;
   font-size: 28px;
   line-height: 42px;
 }
`;

export const SendFormInfo = styled.p`
  height: 60px;
  width: 888px;
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0B3D4C;
  padding: 20px 0 42px 0;
  @media (max-width: 1600px) {
    font-size: 18px;
    line-height: 23px;
    padding: 16px 0 40px 0 ;
    width: 700px;
    height: 46px;
  }
  @media (max-width: 992px) {
    width: 350px;
    padding: 0 ;
    margin: 0;
  }
  @media (max-width: 322px) {
    width: 260px;
    font-size: 16px;
    line-height: 20px;
		margin-top: 10px;
  }
`;
export const SendFormGrayWr = styled.div`
  width: 1006px;
  height: 482px;
  background-color: #F0F1F5;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 80px 40px;
  display: flex;
  gap: 68px;
  @media (max-width: 1600px) {
    height: 419px;
    width: 962px;
    padding: 60px 40px;
  }
 @media (max-width: 1025px) {
    width: 912px;
  }
  @media (max-width: 993px) {
    width: 350px;
    height: 407px;
    border-radius: 40px;
    padding: 40px 12px;
    flex-direction: column;
    gap: 0px;
    margin-top: 70px;
  }
  @media (max-width: 322px) {
    padding: 24px 12px;
    margin-top: 70px;
    height: 379px;
    width: 281px;
  }
`;

export const SendFormForm = styled.div`
  width: 484px;

  & input {
    box-sizing: border-box;
    outline: none;
    width: 484px;
    height: 70px;
    background: #FFFFFF;
    border-radius: 40px;
    margin-bottom: 40px;
    border: none;
    font-family: 'Mulish-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding-left: 40px;
  }
	& .input-error{
    border: 1px solid #EF5A55 !important;
		color:  #EF5A55 !important;
	}
  & .top-mr {
    margin-bottom: 61px !important;
  } 
	& .iti--allow-dropdown {
		margin-bottom: 61px !important;
	}
	
	& .iti--allow-dropdown input.input-error{
      border: 1px solid #EF5A55 !important;
      color:  #EF5A55 !important;
    
	}

  @media (max-width: 1600px) {
    width: 404px;
    & input {
      width: 404px;
    }
  }
  @media (max-width: 992px) {
    width: 325px;
    & input {
      width: 325px;
      height: 48px;
      margin-bottom: 20px;
    }

    & .top-mr {
      margin-bottom: 24px;
    }
  }
  @media (max-width: 322px) {
    width: 260px;
    & input {
      width: 260px;
    }
  }
`;

export const SendFormCheck = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li:first-child {
    white-space: nowrap;
    width: 307px;
    height: 31px;
    font-family: 'MPLUSRounded1c-Regular';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0B3D4C;
    margin-bottom: 16px;

  }

  & li:last-child {
    display: flex;
    gap: 17px;
    width: 320px;
    height: 60px;
    font-family: 'Mulish-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #713FDC;

    & div {
      background-color: #713FDC;
      width: 27px;
      height: 27px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  & input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 26px;
    height: 26px;
    border: 3px solid #C8C5D3;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  & input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    box-shadow: inset 1em 1em #56C423;
  }

  & input[type="radio"]:checked::before {
    transform: scale(1);
  }

  & label {
    display: flex;
    gap: 23px;
    width: 196px;
    height: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #0B3D4C;
    white-space: nowrap;
    margin-bottom: 23px;
  }

  @media (max-width: 1600px) {
    & label {
      gap: 23px;
      margin-bottom: 23px;
      font-size: 18px;
      line-height: 23px;
      width: 194px;
      height: 23px;
    }

    & li:last-child {
    }
  }
  @media (max-width: 992px) {
    & li:first-child {
      width: 230px;
      height: 23px;
      font-size: 18px;
      line-height: 130%;
    }

    & li:last-child {
      font-size: 12px;
      line-height: 15px;
    }

    & label {
      gap: 12px;
      margin-bottom: 14px;
      width: 131px;
      height: 20px;
      font-size: 16px;
      line-height: 20px;
    }

    padding-left: 15px;
  }
`;
export const SendFormFormTryFree = styled.div`
  @media (max-width: 992px) {
    position: absolute;
    bottom: 38px;
  }
 @media (max-width: 322px) {
    position: absolute;
    bottom: 20px;
  }
`;
export const SendFormChar = styled.div`
  height: 535px;
  width: 420px;
  position: absolute;
  bottom: 80px;
  right: -80px;
  @media (max-width: 1600px) {
    height: 423px;
    width: 332px;
    bottom: -165px;
    right: -85px;
    & img {
      width: 100%;
    }
  }
  @media (max-width: 1282px) {
    height: 423px;
    width: 300px;
    bottom: -165px;
    right: -55px;
    overflow: hidden;
    & img {
      height: 423px;
      width: 332px;
    }
  }
  @media (max-width: 1190px) {
    bottom: -195px;
    right: -25px;
    overflow: hidden;

  }
  @media (max-width: 1025px) {
    right: 150px;
    bottom: -220px;
  }
  @media (max-width: 993px) {
    width: 100%;
    bottom: 0;
    top: -65px;
    right: 0;
    left: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
    & img {
      height: 326px;
      width: 237px;
    }
  }
  @media (max-width: 435px){
		display: none;
	}
`;

export const ImgDecorationWr = styled.div`
  position: absolute;
  top: 2.7%;
  left: -2.5%;
  height: 236px;
  overflow: hidden;
  @media (max-width: 1600px) {
    top: -0%;
    left: -7.5%;
    height: 215px;
  }
  @media (max-width: 993px) {
    top: 5%;
    left: 0%;
    height: 215px;
    & img{
      height: 179px;
      width: 128px;
    }
  }
  @media (max-width: 435px) {
    top: 180px;
    left: -7.5%;
    & img{
      height: 129px;
      width: 78px;
    }
  }
`;
export const ImgDecorationWr1 = styled.div`
  position: absolute;
  bottom: -23%;
  left: 22%;

  @media (max-width: 1600px) {
    & img {
      height: 166px;
      width: 266px;
    }

  }
  @media (max-width: 992px) {
    bottom: -130px;
    left: 9%;
  }

`;
export const ImgDecorationWr2 = styled.div`
  position: absolute;
  bottom: -15%;
  left: 15%;
  @media (max-width: 992px) {
    bottom: -10%;
    left: 5%;
  }
`;
export const ImgDecorationWr3 = styled.div`
  position: absolute;
  top: -8%;
  left: 25%;
  @media(max-width: 435px){
    top: 80px;
    left: -30px;
	}
`;
export const ImgDecorationWr4 = styled.div`
  position: absolute;
  top: 20%;
  right: 5%;
	@media(max-width: 435px){
    top: -5px;
		& img{
      width: 15px;
    }
	}
`;

export const PopUpWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  z-index: 20000;
  background: rgba(2, 17, 26, 0.24);
  backdrop-filter: blur(14px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopUpContent = styled.div`
  position: relative;
  height: 778px;
  width: 634px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1282px) {
    height: 701px;
    width: 547px;
  }
  @media (max-width: 1024px) {
    height: 515px;
    width: 360px;

  }
 
`;
export const CharacterPopUp = styled.div`
  height: 395px;
  width: 280px;
  margin-top: -50px;

  & img {
    width: 100%;
  }

  @media (max-width: 1282px) {
    height: 339px;
    width: 241px;
    margin-top: 0px;
  }
  
  @media (max-width: 1024px) {
    margin-top: 30px;
    height: 191px;
    width: 136px;

  }
 
`;
export const OrangeDecoPopUp = styled.div`
  position: absolute;
  bottom: 80px;
  right: -140px;
  overflow: hidden;
  width: 200px;
  box-sizing: border-box;

  & img {
    height: 337px;
    width: 285px;
    margin-left: -110px;
  }

  @media (max-width: 1282px) {
    bottom: 30px;
    right: -147px;
    & img {
      margin-left: -135px;
    }
  }
 
  @media (max-width: 1024px) {
    bottom: -20px;
    right: -20px;
    & img {
      margin-left: 0px;
      height: 137px;
      width: 185px;
    }
  } 
 
	@media (max-width: 322px) {
    display: none;
  }
`;
export const PinkDecoPopUp = styled.div`
  position: absolute;
  top: 185px;
  left: -85px;

  & img {
    height: 274px;
    width: 278px;
  }

  @media (max-width: 1282px) {
    top: 170px;
    left: -115px;
  }
  
@media (max-width: 1024px) {
    top: 120px;
    left: -20px;
  transform: rotate(25deg);
    & img {
      height: 134px;
      width: 138px;
    }

  }
`;
export const BlueDecoPopUp = styled.div`
  position: absolute;
  top: 30px;
  left: 130px;

 @media (max-width: 1024px) {
    top: 70px;
    left: 50px;
		 & img{
       height: 21px;
       width: 19px;
     }
   }
`;
export const LightBlueDecoPopUp = styled.div`
  position: absolute;
  top: 250px;
  right: 150px;
  
 @media (max-width: 1024px) {
    top: 150px;
    right: 80px;
    & img{
      height: 21px;
      width: 19px;
    }
  }

`;
export const SmallGreenDecoPOpUp = styled.div`
  position: absolute;
  top: 78px;
  right: 140px;
  
 @media (max-width: 1024px) {
    top: 50px;
    right: 110px;
    & img{
      height: 12px;
      width: 11px;
    }
  }


`;
export const SmallGreenDecoPOpUpS = styled.div`
  position: absolute;
  top: 235px;
  left: 170px;
  
 @media (max-width: 1024px) { 
		top: 145px;
    left: 70px;
    & img{
      height: 12px;
      width: 11px;
    }
  }

`;
export const HeaderPopUp = styled.p`
  width: 315px;
  height: 52px;
  font-family: 'MPLUSRounded1c-Regular';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: 52px;
  color: #0B3D4C;
  @media (max-width: 1282px) {
    margin-top: -30px;
  }

@media (max-width: 1024px) {
    margin-top: -15px;
    font-size: 28px;
    line-height: 36px;
  }
`;

export const TextPopUp = styled.p`
  width: 458px;
  height: 120px;
  font-family: 'Mulish-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 21px;
  color: #0B3D4D;
  box-sizing: border-box;

  & span {
    font-weight: 500;
    display: block;
    padding: 0 91px;
  }

  @media (max-width: 1282px) {
    font-size: 18px;
    line-height: 23px;
    padding: 0 30px;
    & span {
      padding: 0 0;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 100px;
    margin-top: -5px;
    & span {
      padding: 0 38px;
    }
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CloseBtnPopUp = styled.div`
  position: absolute;
  top: 132px;
  right: 106px;
	cursor: pointer;
  @media (max-width: 1282px) {
    top: 122px;
    right: 96px;
  }

 @media (max-width: 1024px) {
    top: 100px;
    right: 54px;
		& img{
      width: 16px;
      height: 16px;
		}
  }
`;

export const MarketWrapperPopUp = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 40px;

  & img {
    width: 64px;
  }

  @media (max-width: 1282px) {
    margin-top: -5px;
  }

 @media (max-width: 1024px) {
    margin-top: -7px;
    & img {
      width: 48px;
    }

  }
`;

export const PlayWrPopUp = styled.div`
  & img {
    width: 72px;
  }

 @media (max-width: 1024px) {
    & img {
      width: 54px;
    }

  }
`;

export const BgPopUpBottom = styled.div`
  display: none;
 @media (max-width: 1024px) {
    margin-left: 65px;
    margin-top: 5px;
   display: block;
  }

`;