import React from 'react';
import {useTranslation} from "react-i18next";
import {TryFreeBtn} from "./TryFreeBtnStyled";
import {updateTradeItem} from "../../middleware/store/store";
import {scroll} from "../../middleware/scroll";

const TryFree = () => {

	const { t } = useTranslation();
	const handleTryFreeClick = () => {
		updateTradeItem(0, '1101');
		scroll.scrollTo(scroll.anchors.sendForm);
	}

	return (
		<TryFreeBtn>
			<div className="try-btn" onClick={handleTryFreeClick}>{t('try-free')}</div>
		</TryFreeBtn>
	);
};

export default TryFree;