import React from "react";
import styled from "styled-components";

export const CopyWrapper = styled.div`
	width: 100%;
  height: 53px;
	background-color: #F0F1F5;
	color: #EF5A55;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const MarketFooterWrapper = styled.div`
  & p {
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    letter-spacing: 0.02em;
		color: #0B3D4C;
		padding-bottom: 36px;
  }
	& a{
    color: #0B3D4C;
		text-decoration: none;
	}
  & div {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 39px;
    & img {
      width: 64px;
      @media (max-width: 1282px) {
        width: 54px;
      }
    }
  }

`;

export const SocialFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
	& div{
		& img{
			width: 64px;
		}
	}
  @media (max-width: 768px){
		padding-top: 10px;
	}
 @media (max-width: 415px){
   gap: 20px;
   & div{
     & img{
       width: 48px;
     }
   }
	}
`;

export const SocialFooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 34px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    letter-spacing: 0.02em;
		gap: 10px;
  }
`;
export const Email = styled.div`
	display: flex;
	align-items: center;
    color: #0B3D4C;
	gap: 14px;
	font-size: 24px;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0.02em;
	cursor: pointer;
  :hover {
    color: #EF5A55;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 800;
    line-height: 21px;
		padding-bottom: 7px;
  }
`;

export const Phone = styled(Email)`
  color: #0B3D4C;
	cursor: pointer;
  :hover {
    color: #EF5A55;
  }
  @media (max-width: 768px) {
  }
`;
export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 208px 12.4% 116px 12.4%;
	width: 100%;
	box-sizing: border-box;
  @media (max-width: 768px) {
		flex-direction: column;
		gap: 30px;
    padding: 70px 7% 22px 7%;
	}
`;