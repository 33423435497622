const anchors = {
    aboutUs: 'about-us',
    prices: 'prices',
    sendForm: 'send-form',
    specialists: 'specialists',
    disciplines: 'disciplines',
    head: 'head'
}

export const scroll = {
    anchors : anchors,
    scrollTo: (anchor) => {
        const element = document.getElementById(anchor);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}

export const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}