import React from 'react';
import {useTranslation} from "react-i18next";
import PeculiaritiesInfo from "./PeculiaritiesInfo";

import char from "../img/characters-bunny_pup-idle_0.png";
import stars from "../img/stars.png";

import {
	CenterWrapper,
	CharacterWrapper,
	LeftWrapper, PeculiaritiesCont,
	PeculiaritiesHeaderWrapper,
	PeculiaritiesStyle,
	PeculiaritiesStyleFive,
	PeculiaritiesStyleInfoWrapper,
	PeculiaritiesStyleInfoWrapperFive,
	PeculiaritiesStyleInfoWrapperFour,
	PeculiaritiesStyleInfoWrapperThree,
	PeculiaritiesStyleInfoWrapperTwo,
	PeculiaritiesStyleTwo,
	PeculiaritiesWrapperStyle,
	PeculiaritiesWrapperStyleWrapper,
	RightWrapper,
	StarWrapper,
	StarWrapperRight
} from "./PeculiaritiesStyled";
import {scroll} from "../../middleware/scroll";
const Peculiarities = () => {

	const { t } = useTranslation();
	const isFive = true;

	return (
		<PeculiaritiesCont>
			<PeculiaritiesHeaderWrapper id={scroll.anchors.aboutUs}>{t('peculiarities-header')}<img src={stars}/>
			</PeculiaritiesHeaderWrapper>
			<PeculiaritiesWrapperStyleWrapper style={PeculiaritiesWrapperStyle}>
				<CharacterWrapper>
					<img src={char}/>
				</CharacterWrapper>

				<PeculiaritiesInfo StarWrapper={StarWrapper}
													 TestWrapper={LeftWrapper}
													 text={'peculiarities.one'}
													 PeculiaritiesStyle={PeculiaritiesStyle}
													 InfoWrapper={PeculiaritiesStyleInfoWrapper}/>

				<PeculiaritiesInfo StarWrapper={StarWrapperRight}
													 TestWrapper={RightWrapper}
													 text={'peculiarities.two'}
													 PeculiaritiesStyle={PeculiaritiesStyleTwo}
													 InfoWrapper={PeculiaritiesStyleInfoWrapperTwo}/>

				<PeculiaritiesInfo StarWrapper={StarWrapper}
													 text={'peculiarities.three'}
													 TestWrapper={LeftWrapper}
													 PeculiaritiesStyle={PeculiaritiesStyle}
													 InfoWrapper={PeculiaritiesStyleInfoWrapperThree}/>

				<PeculiaritiesInfo	StarWrapper={StarWrapperRight}
														text={'peculiarities.four'}
														TestWrapper={RightWrapper}
														PeculiaritiesStyle={PeculiaritiesStyleTwo}
														InfoWrapper={PeculiaritiesStyleInfoWrapperFour}/>

				<PeculiaritiesInfo StarWrapper={StarWrapper}
													 text={'peculiarities.five'}
													 TestWrapper={CenterWrapper}
													 PeculiaritiesStyle={PeculiaritiesStyleFive}
													 InfoWrapper={PeculiaritiesStyleInfoWrapperFive}
													 isFive={isFive}/>
			</PeculiaritiesWrapperStyleWrapper>
		</PeculiaritiesCont>

	);
};

export default Peculiarities;