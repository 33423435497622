import React from 'react';
import {PriceBtnBg, PriceBtnWr} from "./PriceStyled";
import {t} from "i18next";
import {updateTradeItem} from "../../middleware/store/store";
import {scroll} from "../../middleware/scroll";

const PriceBtn = ({sku, price}) => {

	const handlePriceClick = () => {
		const digitsAndSymbols = price.match(/[0-9.,]+/g);
		const result = digitsAndSymbols ? digitsAndSymbols.join("").replace(/,/g, ".") : "";
	  	updateTradeItem(parseFloat(result), sku);
		scroll.scrollTo(scroll.anchors.sendForm);
	}

	return (
		<PriceBtnWr style={PriceBtnBg} onClick = {handlePriceClick}>
			{t('cost-card.take')}
		</PriceBtnWr>
	);
};

export default PriceBtn;