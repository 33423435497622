import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SubjectsSlider from "./SubjectsSlider";
import {
	SubjectsHeaderWrapper,
	SubjectsNameTopWrapper,
	SubjectsNameWrapper
} from "./SubjectsStyles";
import study from "../img/illustration.png";
import ingForSlideOne from "../img/Mask group-p.png";
import {SLIDERS} from "../../utils";
import {scroll} from "../../middleware/scroll";


const Subjects = () => {

	const [currentIndex, setCurrentIndex] = useState(0);
	const [background, setBackground] = useState(null);
	const [color, setColor] = useState(null);
	const [targetScrollValue, setTargetScrollValue] = useState(0);
	const [animateScroll, setAnimateScroll] = useState(false);

	const scrollContainer = useRef(null);
	const {t} = useTranslation();


	const getOffset = (index) => {
		let offset = 0;
		for(let i=0; i<SLIDERS.length && i < index; i++){
		  const el = document.getElementById(`subject-slide-${i}`);
		  if (el){
			  offset += el.clientWidth + 20; //+ offset
		  }
		}

		return offset;
	}

	const handlerPrevious = () => {
		const isFirstSlide = currentIndex === 0;
		const newIndex = isFirstSlide ? SLIDERS.length - 1 : currentIndex - 1;

		setTargetScrollValue(getOffset(newIndex));
		setAnimateScroll(true);
		setCurrentIndex(newIndex);
	}
	const handlerNext = () => {
		const isLastSlide = currentIndex === SLIDERS.length - 1;
		const newIndex = isLastSlide ?  0 : currentIndex + 1;

		setTargetScrollValue(getOffset(newIndex));
		setAnimateScroll(true);

		setCurrentIndex(newIndex);
	}

	const handlerGoToSlide = (slideIndex) => {
		setCurrentIndex(slideIndex);

	}

	const currentSlide = {
		backgroundColor: background,
		color: color
	}

	useEffect(()=> {
		setBackground(SLIDERS[currentIndex].color);
		setColor('#ffffff');
	},[currentIndex]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!animateScroll){
				return;
			}
			const stepPerFrame = 20;
			const diff = scrollContainer.current.scrollLeft - targetScrollValue;
			if (Math.abs(diff) < stepPerFrame) {
				scrollContainer.current.scrollLeft = targetScrollValue;
				setAnimateScroll(false);
				return;
			}
			if (diff < 0){
				const previous = scrollContainer.current.scrollLeft;

				scrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft + stepPerFrame;

				if (previous === scrollContainer.current.scrollLeft){
					setAnimateScroll(false);
				}
			}else {
				scrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft - stepPerFrame;
			}
		}, 10);

		return () => clearInterval(interval);
	}, [scrollContainer, targetScrollValue, animateScroll]);

	return (
		<div>
			<SubjectsHeaderWrapper id={scroll.anchors.disciplines}>{t('study-way')}
				<img src={study}/>
			</SubjectsHeaderWrapper>
			<SubjectsNameWrapper>
				<SubjectsNameTopWrapper background={background} color={color} ref={scrollContainer}>
					{
						SLIDERS.map((slide, slideIndex) => (
							<li key={slideIndex} id={`subject-slide-${slideIndex}`}
									style={slideIndex === currentIndex ? currentSlide : null}
									onClick={()=>handlerGoToSlide(slideIndex)}>{t(slide.name)}</li>
						))
					}
				</SubjectsNameTopWrapper>
				<SubjectsSlider currentIndex={currentIndex}
												handlerPrevious={handlerPrevious}
												handlerNext={handlerNext}/>
			</SubjectsNameWrapper>
		</div>
	);
};

export default Subjects;