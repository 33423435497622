import React, {useEffect, useState} from 'react';
import {SubjectsHeaderWrapper} from "../subjects-section/SubjectsStyles";
import {useTranslation} from "react-i18next";
import HowItsWorksDescription from "./HowItsWorksDescription";
import imThree from "../img/three.png";
import imTwo from "../img/counting steps.png";
import imOne from "../img/Group 152.png";
import pen from "../img/icon.png";
import {
	HowItsWorksBg,
	HowItsWorksBgSmall, HowItsWorksBgSmaller,
	HowItsWorksContainer,
	HowItsWorksDescriptionHeader,
	HowItsWorksDescriptionHeaderOne,
	HowItsWorksDescriptionHeaderThree,
	HowItsWorksDescriptionInfo,
	HowItsWorksDescriptionInfoOne,
	HowItsWorksDescriptionInfoThree,
	HowItsWorksDescriptionWrapper,
	HowItsWorksImg,
	HowItsWorksImgThree,
	HowItsWorksImgTwo,
	HowItsWorksText, HowItsWorksTextB, HowItsWorksTextContainer,
	HowItsWorksTextH, HowItsWorksTextM,
	HowItsWorksWr, ImPencil
} from "./HowItsWorksStyled";
import TryFree from "../try-free/TryFree";
const HowItsWorks = () => {

	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);
	const [bg, setBg] = useState({});

	useEffect(()=> {
		setWidth(window.innerWidth )
	},[window.innerWidth]);

	useEffect(()=> {
		if(width > 993){
			setBg(HowItsWorksBg);
		}
		if(width > 435 && width < 993){
			setBg(HowItsWorksBgSmall);
		}
		if(width < 435) {
			setBg(HowItsWorksBgSmaller);
		}
	},[width]);

	return (
		<HowItsWorksWr>
			<SubjectsHeaderWrapper>{t('how-its-works-header')} </SubjectsHeaderWrapper>
			<HowItsWorksContainer style={bg}>
				<HowItsWorksDescriptionWrapper>
					<HowItsWorksDescription HowItsWorksDescriptionHeader={HowItsWorksDescriptionHeader}
																	HowItsWorksDescriptionInfo={HowItsWorksDescriptionInfo}
																	numberIs={imOne}
																	HowItsWorksImg={HowItsWorksImg}
																	text={t('how-its-works-info-header.one')}
																	textInfo={t('how-its-works-info.one')}/>
					<HowItsWorksDescription HowItsWorksDescriptionHeader={HowItsWorksDescriptionHeaderOne}
																	HowItsWorksDescriptionInfo={HowItsWorksDescriptionInfoOne}
																	numberIs={imTwo}
																	HowItsWorksImg={HowItsWorksImgTwo}
																	text={t('how-its-works-info-header.two')}
																	textInfo={t('how-its-works-info.two')}/>
					<HowItsWorksDescription HowItsWorksDescriptionHeader={HowItsWorksDescriptionHeaderThree}
																	HowItsWorksDescriptionInfo={HowItsWorksDescriptionInfoThree}
																	numberIs={imThree}
																	HowItsWorksImg={HowItsWorksImgThree}
																	text={t('how-its-works-info-header.three')}
																	textInfo={t('how-its-works-info.three')}/>
				</HowItsWorksDescriptionWrapper>
				<HowItsWorksTextContainer>
					<HowItsWorksText>
						<HowItsWorksTextH>{t('remember')}</HowItsWorksTextH>
						<HowItsWorksTextM>{t('do-remember')}</HowItsWorksTextM>
						<HowItsWorksTextB>{t('not-agree')}</HowItsWorksTextB>
					</HowItsWorksText>
					<TryFree/>
					<ImPencil><img src={pen}/></ImPencil>
				</HowItsWorksTextContainer>
			</HowItsWorksContainer>
		</HowItsWorksWr>
	);
};

export default HowItsWorks;