import React from 'react';
import NavBody from "./NavBody";
import arr from "../img/Vector.png";

import {NavWrapper} from "./HeaderStyled";

const Nav = () => {

	return (
		<NavWrapper>
			<NavBody arr={arr}/>
		</NavWrapper>
	);
};

export default Nav;