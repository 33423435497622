import React from 'react';
import {useTranslation} from "react-i18next";

import Nav from "./Nav";
import NavBurger from "./NavBurger";
import SelectLanguage from "./SelectLanguage";

import arr from "../img/arrGray.png"
import logo from "../img/Logo.png";
import logoSmall from "../img/Logo-small.png";

import {
	BeforeHeader,
	HeaderWrapper,
	LogoNavWrapper,
	LogoWrapper, LogoWrapperSmall,
	SelectLanguageWrapper,
	TryButton
} from "./HeaderStyled";
import {updateTradeItem} from "../../middleware/store/store";
import {scroll} from "../../middleware/scroll";
import {keyCrmApi} from "../../middleware/api/apiKeyCrm";


const Header = () => {

	const { t } = useTranslation();

	const handleTryClick = () => {
		updateTradeItem(0, keyCrmApi.skuCodes.free);
		scroll.scrollTo(scroll.anchors.sendForm);
	};

	return (
		<HeaderWrapper id={scroll.anchors.head}>
			<div>
				<BeforeHeader>
					<p>{t('before-header')}</p>
					<TryButton onClick={handleTryClick}>{t('try')}</TryButton>
				</BeforeHeader>
				<LogoNavWrapper>
					<LogoWrapper onClick={() => window.location.reload(false)}><img src={logo}/></LogoWrapper>
					<LogoWrapperSmall><img src={logoSmall}/></LogoWrapperSmall>
					<Nav/>
				</LogoNavWrapper>
				<NavBurger/>
				<SelectLanguageWrapper>
					<SelectLanguage arr={arr}/>
				</SelectLanguageWrapper>
			</div>
		</HeaderWrapper>
	);
};

export default Header;